import Template from "./template";
import { picker_date } from "../picker_date"; //สำหรับสร้างปฎิทิน
import { useEffect, useState } from "react";
import axios from "axios";
import { server_url } from "../config";
import { date_thai, loading_button, reset_button } from "../lib";

const ApplyDate=()=>{

    const [loading,set_loading]=useState(null);//state สำหรับสถานะกำลังโหลด
    const [success,set_success]=useState(null);//state สำหรับสถานะบันทึกข้อมูลแล้ว
    //function สำหรับบันทึกวันที่ เปิด/ปิด รับสมัคร
    const save=(event)=>{
            //ตรวจสอบว่ากรอกข้อมูล วันที่เปิดรับสมัคร วันที่ปิดรับสมัคร ครบหรือไม่
            if( document.getElementById("start_date").value=="" || document.getElementById("end_date").value=="")
            {
                window.show_notification("ข้อมูลไม่ครบ","กรุณากรอกข้อมูลให้ครบ");//ถ้ากรอก  วันที่เปิดรับสมัคร วันที่ปิดรับสมัคร ไม่ครบให้ขึ้นข้อความเตือน
                return;
            }
            //เปลี่ยนสถานะปุ่มเป็นกำลังโหลด...
            let b=event.currentTarget;
            loading_button(b);

            let form_data=new FormData();
            form_data.append("mode","save");
            form_data.append("token",localStorage["token"]);//ส่ง token เพื่อแสดงว่า login แล้ว
            //ส่งข้อมูลวันที่เปิดรับสมัคร ปิดรับสมัคร ไปบันทึกที่ "api/apply_date.php"
            form_data.append("start_date",document.getElementById("start_date").value);
            form_data.append("end_date",document.getElementById("end_date").value);

            axios.post(server_url()+"api/apply_date.php",form_data).then(response=>{
                //ชุดคำสั่ง เมื่อบันทึกเสร็จแล้ว
                reset_button(b);//เปลี่ยนสถานะปุ่มเป็น เป็นปกติ
                set_success(<div className="alert alert-success mt-2">บันทึกข้อมูลแล้ว</div>);//ขึ้นหน้าเว็บว่า บันทึกข้อมูลแล้ว
                setTimeout(() => {
                    set_success(null);//ปิด ข้อความ บันทึกข้อมูลแล้ว หลังผ่านไป 2 นาที
                }, 2000);

            });

    }

    // function ที่เริ่มทำงานเมื่อเปิดหน้าเว็บมาให้ 
    useEffect(()=>{

        set_loading(<div className="alert alert-warning">กำลังโหลด...</div>);//ขึ้นข้อความกำลัง โหลด...
        //กำหนดให้ Element ที่มี  id เป็น start_date และ end_date เป็นตัวเลือก แบบ ปฎิทิน
        picker_date(document.getElementById("start_date"),{year_range:"-1:+1"});
        picker_date(document.getElementById("end_date"),{year_range:"-1:+1"});

        //------ดึงข้อมูลวันที่เปิด - รับสมัคร โดยเรียกไฟล์ "api/apply_date.php"
        let form_data=new FormData();
        form_data.append("mode","get");
        form_data.append("token",localStorage["token"]);

        axios.post(server_url()+"api/apply_date.php",form_data).then(response=>{
            //ชุดคำสั่งเมื่อ "api/apply_date.php" ข้อมูลมาแล้ว 

            set_loading(null);//ซ่อนข้อความกำลังโหลด...
            document.getElementById("start_date").value=date_thai(response.data.start_date);//นำข้อมูลวันที่เปิดรับสมัคร จากฐานข้อมูลใส่ใน text box วันที่เปิดรับสมัคร โดยใช้ function  date_thai เพื่อเปลี่ยนเป็นวัน/เดือน/ปี
            document.getElementById("end_date").value=date_thai(response.data.end_date);//นำข้อมูลวันที่ปิดรับสมัคร จากฐานข้อมูลใส่ใน text box วันที่ปิดรับสมัคร โดยใช้ function  date_thai เพื่อเปลี่ยนเป็นวัน/เดือน/ปี


        });



    },[]);


    return <Template>

        <h4>วันที่เปิด-ปิด การรับสมัคร</h4>
        <hr />

        {loading} {/* แสดงสถานะกำลังโหลด.....  */}

        <div className="row">
            <div className="col-sm-4">
                <div className="mb-3 ">
                    {/*  textbox วันที่เปิดรับสมัคร */}
                    <label className="form-label">วันที่เปิดรับสมัคร</label>
                    <input className="form-control form-control-sm" id="start_date" />
                </div>
            </div>
            <div className="col-sm-4">
                <div className="mb-3 ">
                     {/*  textbox วันที่ปิดรับสมัคร */}
                    <label className="form-label">วันที่ปิดรับสมัคร</label>
                    <input className="form-control form-control-sm" id="end_date" />
                </div>
            </div>
            <div className="col-sm-4"></div>
            <div className="col-sm-4">
                 {/* ปุ่ม บันทึกข้อมูล เมื่อกดแล้ว จะไปทำงาน ที่ function save */}
                    <button className="btn btn-sm btn-primary" data-loading-text="กำลังบันทึก..." onClick={event=>{
                        save(event);
                    }}>
                        <i className="fa fa-check"></i> บันทึกข้อมูล
                    </button>
            </div>
        </div>

        {success}

    </Template>
}

export default ApplyDate;
