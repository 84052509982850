import { useEffect,useState } from "react";
import Template from "./template";
import axios from 'axios';
import {server_url} from "../config";
import { date_thai, loading_button, reset_button } from "../lib";


const PDF=()=>{

    const [year,set_year]=useState([]);//ปีการศึกษาที่เปิดรับสมัคร ( สำหรับใส่ในตัวเลือก )
    const [register_year,set_register_year]=useState("");//ปีการศึกษาที่เลือก
    const [sport,set_sport] =useState([]);
    const [sport_id,set_sport_id] =useState("");
    const [transaction_type,set_transaction_type]=useState([]);
    const [transaction_type_id,set_transaction_type_id]=useState("");

    const export_pdf=()=>{

        if(register_year=="" || transaction_type_id=="" || sport_id=="")// ถ้าข้อมูลไม่ครบให้ขึ้นข้อความแจ้งเตือน
        {
            window.show_notification("ข้อมูลไม่ครบ","กรุณาเลือก สถานะ กีฬา และปีการศึกษาที่สมัคร");
            return;
        }

        if(transaction_type_id=="-1")
        {
            window.show_notification("ข้อมูลไม่ถูกต้อง","ไม่สามารถแสดงผู้สมัครที่ยังไม่มีสถานะได้");
            return;
        }

        window.open(`#/admin/exportpdf/${register_year}/${transaction_type_id}/${sport_id}`,"_blank");

    }

    useEffect(  () => {
        //ชุดคำสั่งที่ทำงานเมื่อ โหลดหน้าเว็บเสร็จ
        (async function(){
            
            let form_data=new FormData();
            form_data.append("mode","get_sport");
            form_data.append("all","1");//all แสดงว่า เอา  disabled  ด้วย

            let response=await axios.post(server_url()+"api/register.php",form_data);
            set_sport(response.data);

            form_data=new FormData();//หาสถานะการสมัครที่มีอยู่ใน database
            form_data.append("mode","get_transaction_type");
            response=await axios.post(server_url()+"api/registeration.php",form_data);
            response.data=response.data.filter(item=>{ return (item.transaction_type_id < 5) });//ไม่เอาสาุนะไม่มีสิทธิ
            set_transaction_type(response.data);//เมื่อหาเสร็จแล้วเก็บไว้ใน state transaction_type
 

            form_data=new FormData();
            form_data.append("mode","get_register_year");//หาปีการศึกษาที่มีปู้สมัคร
            response=await axios.post(server_url()+"api/registeration.php",form_data);
            set_year(response.data);//เมื่อหาเสร็จแล้วเก็บไว้ใน state year


        })();
        

    },[]);


    return(<Template>

        <div className="mt-2 row">

            <div className="col-sm-3">
                <div className="mb-3">
                    <label className="form-label">ปีการศึกษาที่สมัคร</label>
                    {/*   ตัวเลือก ปีการศึกษา */}
                    <select className="form-select form-select-sm" value={register_year} 
                        onChange={event=>set_register_year(event.target.value)} >{/* ถ้าเปลี่ยนตัวเลือก จะเก็บปีการศึกษาที่เลือกไว้ใน state register_year */}
                        
                        <option value=""></option>
                        {year.map(item=>
                            <option value={item.register_year}>{parseInt(item.register_year)+543}</option>    
                        )}

                    </select>
                </div>
            </div>

            <div className="col-sm-3">
                <div className="mb-3">
                    {/*  ตัวเลือกสถานะผู้สมัคร */}
                    <label className="form-label">สถานะ</label>
                    <select className="form-select form-select-sm" value={transaction_type_id} 
                        onChange={event=>{ set_transaction_type_id(event.target.value) }}
                    >{/* ถ้าเปลี่ยนตัวเลือก จะเก็บปีการศึกษาที่เลือกไว้ใน state transaction_type_id */}
                        
                        <option value=""></option>
                        {transaction_type.map(item=>
                                    <option value={item.transaction_type_id}>
                                        {item.transaction_type_name}
                                    </option>    
                        )}

                    </select>
                </div>
            </div>
            
            <div className="col-sm-3">
                <div className="mb-3">
                    <label className="form-label">กีฬา</label>
                    {/*   ตัวเลือก ปีการศึกษา */}
                    <select className="form-select form-select-sm" value={sport_id} 
                        onChange={event=>set_sport_id(event.target.value)} >{/* ถ้าเปลี่ยนตัวเลือก จะเก็บปีการศึกษาที่เลือกไว้ใน state register_year */}
                        
                        <option value=""></option>
                        {sport.map(item=>
                            <option value={item.sport_id}>
                                {item.sport_name} 
                                {item.gender!=""?" ( "+item.gender+" )":null}
                                {item.age1!="0"?" อายุ "+item.age1+" ถึง "+item.age2+" ":null}
                            </option>      
                        )}

                    </select>
                </div>
            </div>

            <div className="col-sm-3">
                <div className="mb-3" style={{marginTop:"30px"}}>
                    <label className="form-label"> </label>
                    <button className="btn btn-sm btn-success " type="button" data-loading-text="กำลังโหลด..." 
                        onClick={event=>{ export_pdf() }} >
                        <i className="fa fa-download"></i> Download
                    </button>
                   
                </div>
            </div>
        

        </div>


    </Template>);
}

export default PDF;