import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useEffect, useState ,useImperativeHandle,forwardRef, useRef } from 'react';

const ConfirmPopup=(props,ref)=>{

    //const [confirm_function,set_confirm_function]=useState(null);
    let confirm_function=null;
    
   
    const open=(detail,click_confirm)=>{

        document.getElementById("confirm_popup_modal_div").innerHTML=detail;
        var myModal = new bootstrap.Modal(document.getElementById('confirm_popup_modal'), {});
        myModal.show();
        confirm_function=click_confirm;
    };

    const click=async ()=>{

        var myModal = new bootstrap.Modal(document.getElementById('confirm_popup_modal'), {})
        myModal.hide();

        confirm_function();

        
    }

    useImperativeHandle(ref ,()=>{
        return {
            "open":open 
        }
    });


    return (<div className="modal fade" id="confirm_popup_modal" tabIndex="-1" aria-labelledby="notification_popup_header" aria-hidden="true">
    <div className="modal-dialog">
        <div className="modal-content">
        <div className="modal-header">
            <h5 className="modal-title">ยืนยัน</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body text-center">

            <div id="confirm_popup_modal_div"></div>

            <div className="mt-3">
                    <button type="button" className="btn btn-sm btn-primary" data-bs-dismiss="modal"
                        onClick={event=>{
                           click();
                        }}
                    >ยืนยัน</button>&nbsp;
                     <button type="button" className="btn btn-sm btn-danger" data-bs-dismiss="modal"
                        onClick={event=>{
                             
                            var myModal = new bootstrap.Modal(document.getElementById('confirm_popup_modal'), {})
                            myModal.hide();
                        }}
                    > ยกเลิก</button>
            </div>
            
        </div>
        
        </div>
    </div>
    </div>);
}

export default forwardRef(ConfirmPopup);