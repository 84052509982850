import Template from "./template";
const AdminHome=()=>{

    return (<Template>

        

    </Template>);
}

export default AdminHome;