import { useEffect, useState ,useImperativeHandle,forwardRef, useRef } from 'react';
import $ from 'jquery';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';


function Popup(props,ref){

    const open=(header,detail)=>{

        $("#notification_popup_header").html(header);
        $("#notification_popup_div").html(detail);

        var myModal = new bootstrap.Modal(document.getElementById('notification_popup'), {});
        myModal.show();
    };

    useImperativeHandle(ref ,()=>{
        return {
            "open":open
        }
    });

    return (
 
        <div className="modal fade" id="notification_popup" tabIndex="-1" aria-labelledby="notification_popup_header" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="notification_popup_header"></h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-center">

                <div id="notification_popup_div"></div>

                <div className="text-center mt-3">

                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                        onClick={event=>{
                            //document.getElementById('notification_popup').hide();
                            var myModal = new bootstrap.Modal(document.getElementById('notification_popup'), {})
                            myModal.hide();
                        }}
                    >ตกลง</button>
                    
                </div>
                
            </div>
            
            </div>
        </div>
        </div>

    );
}

export default forwardRef(Popup);