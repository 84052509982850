import 'bootstrap/dist/css/bootstrap.min.css';//import bootstrap
import 'bootstrap/dist/js/bootstrap.bundle.js';
import { useEffect,useState } from 'react';
import { check_apply_date } from './check_apply_date';//ตรวจสอบว่า ยังอยู่ในช่วงเปิดรับสมัคร หรือไม่

const Template =(props)=>{

    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const [can_apply, set_can_apply] = useState("");

    const handleNavCollapse = () => {//สลับค่า เปิดเปิด เมนูสำหรับ มือถือ

        setIsNavCollapsed(!isNavCollapsed);

    }

    useEffect(()=>{

        
    },[isNavCollapsed]);

    useEffect(async ()=>{

        //เปิดมาให้ตรวจสอบว่าตอนนี้เปิดรับสมัครหรือไม่
        let check_result=await check_apply_date();
        //เก็บ state ว่าตอนนี้อยู่ในรหะหว่างรับสมัคร หรือไม่ ( 0 หรือ 1)
        set_can_apply(check_result);


    },[]);

    

    return (<div>

        <nav className="navbar navbar-expand-lg navbar-dark bg-success fixed-top">
            <div className="container-fluid">
                <a className="navbar-brand" style={{fontSize:"14px"}} href="#">ระบบการรับสมัครนักเรียนโรงเรียนกีฬากรุงเทพมหานคร</a>
                {/* ถ้ากดปุ่ม จะไปทำงานที่ function handleNavCollapse */}
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
                    aria-expanded="true"
                    onClick={handleNavCollapse} 
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button> 

                <div  className={` navbar-collapse text-right`}   id="navbarSupportedContent" style={{display:(isNavCollapsed)?"none":""}}>
                   
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">

                        <li className="nav-item">
                            <a className="nav-link active"   href="#/register">สมัครเรียน</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active"  href="#/search_registeration"  >ดาวน์โหลดใบสมัคร</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" href="#/announcement"  >ดูผลการสมัครเรียน</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active"  href="#/schedule_and_document" >กำหนดการและเอกสาร</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link active" href="#/admin">สำหรับผู้ดูแลระบบ</a>
                        </li>
                       
                        
                    </ul>
                
                </div>
            </div>
        </nav>

        {/* -----ถ้ามีการส่ง property header มาจะแสดง รูป header ( ดูในไฟล์ home.js ) ---- */}
        {(props.header!=null)?<img src={props.header} className="mt-5" style={{width:"100%"}} />:null}
        <div className="container mt-5  " >
            {(props.header==null)?<br />:null}
            {(props.header==null)?<br />:null}
            {/*  เนื้อหาของ หน้าเว็บ */}
            {props.children}
        </div>

        <div className="text-danger text-center mt-4 mb-5">

            {(can_apply=="" || can_apply=="1")?<u>สอบถามปัญหาการใช้งานระบบการสมัครเรียนได้ที่ 087-670-0406</u>:null}

            {(can_apply=="0")?<u>ขณะนี้โรงเรียนกีฬากรุงเทพมหานครได้ปิดการรับสมัครนักเรียนแล้ว&nbsp;
             &nbsp; ท่านสามารถสอบถามข้อมูลเพิ่มเติมได้ที่ 02-245-4756 และ 087-670-0406 หรือ <br /> <a href="https://bit.ly/3xsei1U" target="_blank">ทาง Line โรงเรียนกีฬากรุงเทพมหานคร</a></u>:null}
        </div>
        
        

    </div>);
};

export default Template;