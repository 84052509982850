import Template from "./template";
import '@fortawesome/fontawesome-free/css/all.css';
import { useHistory } from "react-router-dom";

const Home=()=>{

    const history=useHistory();
    return (<>
    
        <Template header="img/bannerSportsSchool.jpg"> {/*    header="img/bannerSportsSchool.jpg" ส่งรูป header ไปให้ template แสดง */}
            
            <div className="row text-center">
                {/*
                
                    history.push(....) คือ link ไปหน้าต่างๆ ตาม path ที่ระบุไว้ใน App.js

                */}
                <div className="col-sm-4 mb-2">{/* Link ไปหน้า สมัครเรียน */}
                    <button className="btn btn-danger" style={{width:"80%"}} type="button" 
                    onClick={event=>{ history.push("/register") }}> <i className='fa fa-pencil-alt'></i> สมัครเรียน</button>
                    
                </div>

                <div className="col-sm-4 mb-2">{/* หน้า Link ไปหน้า Download ใบสมัคร */}
                    <button className="btn btn-danger" style={{width:"80%"}} type="button" 
                        onClick={event=>{ history.push("/search_registeration")  }}
                    > <i className='fa fa-print'></i> ดาวน์โหลดใบสมัคร</button>
                </div>
 
                <div className="col-sm-4 mb-2">
                    <button className="btn btn-danger" style={{width:"80%"}} type="button" 
                        onClick={event=>{ history.push("/schedule_and_document") }}
                    > <i className='fa fa-calendar-alt'></i> กำหนดการและเอกสาร</button>
                </div>

               

            </div>

            <br />
            <br />
            <br />

        </Template>    
    </>);
}

export default Home;