import Template from "./template";
import DocumentFileForm from "./document_file_form";
import { useEffect, useRef, useState } from "react";
import { useHistory,useParams } from "react-router";
import { date_thai, loading_button, reset_button } from "../lib";
import axios from "axios";
import { server_url } from "../config";

const DocumentFile=(props)=>{

    const history=useHistory();
    const {form_mode}  = useParams();
    const form_ref=useRef();

    const [sel_index,set_sel_index]=useState(-1);

    const [current_page, set_current_page] = useState(0);
    const [loading, set_loading] = useState(false);
    const [data_table, set_data_table] = useState([]);
    const [total_page, set_total_page] = useState(0);
    const page_size=10;

    const open_form=(row,index)=>{

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
       
        if(row==null)
        {
            set_sel_index(-1);
            history.push('/admin/document_file/form/');
        }
        else
        {
            set_sel_index(index);
            history.push('/admin/document_file/form/'+row.document_file_id);
        }
        
    }
    const load_page=async (page_num)=>{

        if( ( page_num==null || page_num=="") && (page_num!=0))
        {
            page_num=current_page;
        }

        set_sel_index(-1);
        set_data_table([]);
        set_loading(true);
        set_total_page(0);
        set_current_page(parseInt(page_num));

        let json=null;
        let form_data=new FormData();
        form_data.append("token",localStorage["token"]);
        form_data.append("mode","get");
        form_data.append("page_size",page_size);

        let response;
        try
        {
            response =await axios.post(server_url()+"api/document_file.php",form_data);
            json=response.data;

            if(typeof (json)!="object")
            {
                console.log(json);
                return;
            }
        }
        catch(ex)
        {
            console.log(ex);
            console.log(response);
            return;
        }

        json=response.data;

        
        if(json.length==0 && page_num!=0)
        {
            page_num--;
            load_page(page_num);
            return;
        }
        
        set_loading(false);
        set_total_page(json.total_page);
        set_data_table(json.data_table);

    }

    useEffect(()=>{
        
        (async function(){

            await load_page();

        })()
        

    },[]);

    // useEffect(()=>{
        
    //     (async function(){

    //         set_sel_index(-1);

    //     })()
        

    // },[props]);

    const render_paging=()=>{

        if( total_page==0 ||  total_page==1 )
        {
            return null;
        }

        /*----
        
        ไม่ให้ปุ่มมีกรอบ
        <style>
                .page-link:focus {
            outline: none !important;
            }
        </style>

        ---- */


        return (<nav   >
        <ul className="pagination mt-3 justify-content-center">
        
        {(current_page!=0)?<li className="page-item"><button onClick={() => load_page(0)} className="page-link disabled" type="button" ><i className="fa fa-step-backward"></i></button></li>:null}
        {(current_page!=0)?<li className="page-item"><button  onClick={() => load_page(current_page-1)} className="page-link" type="button" ><i className="fa fa-chevron-left"></i></button></li>:null}

          {((current_page-3) > 0)?<li className="page-item"><button className="page-link"  type="button" > ... </button></li>:null}
                
                {((current_page-2) >= 0)?<li className="page-item"><button  onClick={() => load_page(current_page-2)}  className="page-link"  type="button" >{current_page-1}</button></li>:null}
                {((current_page-1) >= 0)?<li className="page-item"><button  onClick={() => load_page(current_page-1)} className="page-link"  type="button" >{current_page}</button></li>:null}
                {((current_page) >= 0)?<li className="page-item"><button className="page-link text-danger"  type="button" >{current_page+1}</button></li>:null}
                {((current_page+1) < total_page)?<li className="page-item"><button onClick={() => load_page(current_page+1)} className="page-link"  type="button" >{current_page+2}</button></li>:null}
                {((current_page+2) < total_page)?<li className="page-item"><button onClick={() => load_page(current_page+2)} className="page-link"  type="button" >{current_page+3}</button></li>:null}
            
            {((current_page+3) < total_page)?<li className="page-item"><button className="page-link"  type="button" > ... </button></li>:null}
        
        {(current_page+1 < total_page)?<li className="page-item"><button  onClick={() => load_page(current_page+1)}  className="page-link" type="button" ><i className="fa fa-chevron-right"></i></button></li>:null}
        {(current_page+1 < total_page)?<li className="page-item"><button style={{outline:"none"}}  onClick={() => load_page(total_page-1)}className="page-link" type="button" > <i className="fa fa-step-forward"></i></button></li>:null}

        </ul>
      </nav>)

    }

    return (<Template>
       
            <h4>เอกสาร</h4>
            <hr />
            {form_mode=="form"?    

                <DocumentFileForm ref={form_ref} refresh={load_page}  />
                
            :null}

            {form_mode!="form"?<button className="btn btn-sm btn-success" type="button" onClick={event=>open_form(null)} >
                        <i className="fa fa-plus"></i> เพิ่มเอกสาร
            </button>:null}

            <ul className="list-group mt-3">
                        {(loading)?
                            <li className="list-group-item list-group-item-warning" >
                                กำลังโหลด.. 
                            </li>
                        :null}

                        {(!loading && data_table.length==0 )?
                            <li className="list-group-item list-group-item-danger" >
                                ไม่มีข้อมูล
                            </li>
                        :null}

                        {data_table.map((row,index)=>

                        <li 
                            className={"list-group-item "+((sel_index==index &&  form_mode=="form")?"list-group-item-warning":"")}>
                            
                            <div className="row">
                                <div className="col-sm-10">

                                    <div><b className="text-primary">ชื่อเอกสาร </b> : { row.document_file_name } </div>
                                    <div><b className="text-primary">Link </b> : 
                                    &nbsp;<a href={row.document_file_link} target="_blank">{ row.document_file_link }</a> </div>


                                </div>
                                <div className="col-sm-2">

                                    <div className="d-grid gap-2">
                                        <button className="btn btn-primary btn-sm" type="button"
                                            onClick={event=>{
                                                open_form(row,index);
                                            }}
                                        >
                                            <i className="fa fa-pencil-alt" ></i> แก้ไข
                                        </button>
                                        <button className="btn btn-danger btn-sm" type="button" data-loading-text="กำลังลบ..."
                                            onClick={event=>{
                                                let b=event.currentTarget;
                                                window.show_confirm("ต้องการลบ "+(row.document_file_name)+" ?",()=>{

                                                    loading_button(b);

                                                    let form_data=new FormData();
                                                    form_data.append("mode","del");
                                                    form_data.append("document_file_id",row.document_file_id);
                                                    form_data.append("token",localStorage["token"]);

                                                    axios.post(server_url()+"api/document_file.php",form_data).then(response=>{

                                                        reset_button(b);

                                                        if(typeof(response.data)!="object")
                                                        {
                                                            console.log(response.data);
                                                            return;
                                                        }

                                                        if(response.data.error!=null)
                                                        {
                                                            window.show_notification("ไม่สามารถลบได้",response.data.error)
                                                            return;
                                                        }
                                                        //window.show_notification("ไม่สามารถลบได้","");

                                                        load_page(current_page);

                                                    })


                                                });
                                            }}
                                        >
                                            <i className="fa fa-trash"></i>  ลบ
                                        </button>
                                    </div>

                                </div>
                            </div>
                            
                            
                        </li>

                        )}

            </ul>
        

    </Template>);
}

export default DocumentFile;