import { forwardRef, useEffect, useImperativeHandle, useState ,useRef , useLayoutEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { server_url } from "../config";
import axios from "axios";
import Upload from "../upload";
import { eng_only, loading_button, reset_button ,check_int, date_thai } from "../lib";
import { picker_date } from "../picker_date";
import { getSportDisplay } from "../sport_display_text";


const RegisterationForm = (props,ref)=>{

    const {id}=useParams();
    const [err,set_err]=useState("");
    
    const [age,set_age]=useState("");
    const [province,set_province]=useState([]);
    const [sport,set_sport]=useState([]);
    const [edu_level,set_edu_level]=useState([]);
    const [sport_display,set_sport_display]=useState([]);

    const [title,set_title]=useState("");
    const [name,set_name]=useState("");
    const [family_name,set_family_name]=useState("");
    const [citizen_id,set_citizen_id]=useState("");
    const [telephone_number,set_telephone_number]=useState("");
    const [telephone_number_parent,set_telephone_number_parent]=useState("");
	const [school,set_school]=useState("");
    const [province_id,set_province_id]=useState("");
    const [address,set_address]=useState("");
    const [sport_id,set_sport_id]=useState("");
    const [edu_level_id,set_edu_level_id]=useState("");
    const [email,set_email]=useState("");

    const [applicant_picture,set_applicant_picture]=useState("");
    const [citizen_picture,set_citizen_picture]=useState("");
    const [birth_certificate_picture,set_birth_certificate_picture]=useState("");

    const [success,set_success]=useState(false);
    const [success_response,set_success_response]=useState(false);

    const didMount = useRef(false);
    const current_id = useRef("");
    const history=useHistory();

    const [loading,set_loading]=useState(false);

    const save=async (event)=>{

         

        if(
            title=="" ||
            name=="" ||
            family_name=="" ||
            citizen_id=="" ||
            telephone_number=="" ||
            telephone_number_parent=="" ||
            school=="" ||
            province_id=="" ||
            address=="" ||
            sport_id=="" ||
            email=="" ||
            applicant_picture=="" ||
            citizen_picture=="" ||
            birth_certificate_picture=="" ||
            edu_level_id=="" 
        )
        {
            set_err("กรุณากรอกข้อมูลให้ครบ");
            return;
        }

        if(citizen_id.length!=13)
        {
            set_err("เลขที่ประจำตัวประชาชนไม่ครบ 13 หลัก");
            return;
        }

        if(telephone_number.length!=10)
        {
            set_err("เบอร์โทรศัพท์ไม่ครบ 10 หลัก");
            return;
        }

        if( email.indexOf("@")==-1 && email.indexOf(".")==-1 )
        {
            set_err("รูปแบบ Email ไม่ถูกต้อง");
            return;
        }

        let form_data=new FormData();
        form_data.append("mode","edit");
        form_data.append("token",localStorage["token"]);
        form_data.append("registeration_id",id);
        form_data.append("birth_date",document.getElementById("birth_date").value);
        
        form_data.append("title",title);
        form_data.append("name",name);
        form_data.append("family_name",family_name);
        form_data.append("citizen_id",citizen_id);
        form_data.append("telephone_number",telephone_number);
        form_data.append("telephone_number_parent",telephone_number_parent);
        form_data.append("school",school);
        form_data.append("province_id",province_id);
        form_data.append("address",address);
        form_data.append("sport_id",sport_id);
        form_data.append("email",email);
        form_data.append("applicant_picture",applicant_picture);
        form_data.append("citizen_picture",citizen_picture);
        form_data.append("birth_certificate_picture",birth_certificate_picture);
        form_data.append("edu_level_id",edu_level_id);


        set_err("");
        let b=event.currentTarget;
        loading_button(b);

        let response="";
        let json="";
        try {
            
            response=await axios.post(server_url()+"api/registeration.php",form_data);
            //console.log(response);
            reset_button(b);
            json=response.data;


            if(json.error!=null)
            {
                //console.log(response);
                set_err(response.data.error);
                return;
            }

        } catch (err) {

            set_err(response.data.error);
            return;
        }

        set_err(<div className="alert alert-success">บันทึกข้อมูลแล้ว</div>);
        setTimeout(()=>{
            props.refresh();
            history.replace("/admin/registeration");
        },1500);
        

        //console.log(response.data);
        //set_success(true);
        //set_success_response(response.data);
        //window.open(server_url()+"pdf/index.php?user_key="+response.data.user_key,"_blank");
        //window.open(server_url()+"pdf/index.php?user_key="+response.data.user_key,"_blank");

    }

    const load_data= async ()=>{
         
        
        if(id!="")
        {
            set_loading(true);

            let formData=new FormData();
            formData.append("mode","get_province");
            
            let response=await axios.post(server_url()+"api/register.php",formData);
            
            set_province(response.data);

            formData=new FormData();
            formData.append("mode","get_sport");
            //formData.append("all","1");//all แสดงว่า เอา  disabled  ด้วย

            response=await axios.post(server_url()+"api/register.php",formData);
            set_sport(response.data);

            formData= new FormData();
            formData.append("mode","get_edu_level");

            response=await axios.post(server_url()+"api/register.php",formData);
            set_edu_level(response.data);

            formData=new FormData();
            formData.append("mode","get");
            formData.append("registeration_id",id);
            formData.append("page_size","1");
            formData.append("page_num","0");

            response=await axios.post(server_url()+"api/registeration.php",formData);
            
            let data=response.data["data_table"][0];
            
            document.getElementById("birth_date").value=date_thai(data.birth_date);
            set_title(data.title);
            
            set_name(data.name);
            set_family_name(data.family_name);
            set_citizen_id(data.citizen_id);
            set_email(data.email);
            set_edu_level_id(data.edu_level_id);
            set_telephone_number(data.telephone_number);
            set_telephone_number_parent(data.telephone_number_parent);
            set_school(data.school);
            set_province_id(data.province_id);
            set_address(data.address);

            set_applicant_picture(data.applicant_picture);
            set_citizen_picture(data.citizen_picture);
            set_birth_certificate_picture(data.birth_certificate_picture);

            set_loading(false);
            set_age(get_age(data.birth_date));
            set_sport_display(get_sport(data.title,get_age(data.birth_date)));
            set_sport_id(data.sport_id);
            
            //alert(id);
             
        }
     
   }

    const get_sport=(title,age/*,edu_level_id*/)=>{

       
        if( age!="" && title!="" && edu_level_id!="" /*&& edu_level_id!=null*/)
        {
           
            let out_put=[];
            let temp=[...sport];
            temp.map(item=>{

                if(item.gender=="" && item.age1=="0" && item.age2=="0" /*&& item.min_edu_level==null && item.max_edu_level==null*/ )
                {
                    out_put.push(item);
                }
                else{

                        let flag1=0;
                        let flag2=0;
                        //let flag3=0;
                        
                        if(item.gender!="")
                        {
                            if(item.gender=="ชาย")
                            {
                                if(title=="นาย" || title=="เด็กชาย")
                                {
                                    flag1=1;
                                }
                                

                                //item.sport_name+=" ( ชาย ) ";

                            }   

                            if(item.gender=="หญิง")
                            {
                                //console.log(item.gender);
                                if(title=="นางสาว" || title=="เด็กหญิง")
                                {
                                    flag1=1;
                                }

                                //item.sport_name+=" ( หญิง ) ";
                            }
                            
                        }
                        else
                        {
                            flag1=1;
                        }
                        
                        if( item.age1!="0" && item.age2!="0")
                        {
                            //console.log(item.age1);
                            if( 
                                parseInt(item.age1) <= parseInt(age) &&
                                parseInt(age) <=  parseInt(item.age2)  
                            )
                            {
                                
                                flag2=1;
                                
                            }

                            //item.sport_name=item.sport_name+" ( อายุ "+item.age1+" ปี ถึง "+item.age2+" ปี )"
                        }
                        else
                        {
                            flag2=1;
                        }

                        // if( item.min_edu_level!=null && item.max_edu_level!=null)
                        // {
                        //     //console.log(item.age1);
                        //     if( 
                        //         parseInt(item.min_edu_level.edu_level_id) <= parseInt(edu_level_id) &&
                        //         parseInt(edu_level_id) <=  parseInt(item.max_edu_level.edu_level_id)  
                        //     )
                        //     {
                        //         flag3=1;
                        //     }
                        // }
                        // else
                        // {
                        //     flag3=1;
                        // }
                        

                        if(flag1==1 && flag2==1 /*&& flag3==1*/)
                        {
                            out_put.push(item);
                        }
                }

            });

            return out_put;
            //set_sport_display(out_put);

            //console.log(sport);
        }

        return [];

    }

    useEffect(()=>{

        picker_date(document.getElementById("birth_date"),{year_range:"-30:0",onchange:(date)=>{
            
            if(date=="")
            {
                set_age("");
                return;
            }
           
            let date_arr=date.split("/");
            date=(date_arr[2]-543)+"-"+date_arr[1]+"-"+date_arr[0];
            set_age(get_age(date));

        }});

        if(id!=null)
        {
            load_data();
        }

    },[id]);
 

    const get_age=(DOB)=>
    {
        if(DOB=="")
        {
            return "";
        }

        //----นับ 1 มกราคม------
        let arr=DOB.split("-");
        arr[0]=arr[0];
        arr[1]="01";
        arr[2]="01";
        DOB=arr[0]+"-"+arr[1]+"-"+arr[2];

            var today = new Date();
            var birthDate = new Date(DOB);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age = age - 1;
            }

            return age;
    }

    useEffect(()=>{  
        
        if(didMount.current==false)
        {
            didMount.current=true;
            return;
        }
        set_sport_id("");
        set_sport_display([]);
        set_sport_display(get_sport(title,age,edu_level_id)); 
        
    },[title,age,edu_level_id]);

    useImperativeHandle(ref ,()=>{
        
        return {
            "load_data":load_data,
        }
        
    });

    return (<div>

            <div className='card mb-5'>
                <div className='card-body'>

                {(loading)?
                    <div className="alert alert-warning" >กำลังโหลด....</div>
                :null}
                        
                <div className="row">

                        <div className="col-sm-4 mb-3">

                                <label className="form-label">คำนำหน้า</label>
                                <select className="form-select form-select-sm"  value={title} onChange={event=>set_title(event.target.value)} >
                                    <option value=""></option>
                                    <option value="นาย">นาย</option>
                                    <option value="นางสาว">นางสาว</option>
                                    <option value="เด็กชาย">เด็กชาย</option>
                                    <option value="เด็กหญิง">เด็กหญิง</option>
                                </select>
                        </div>

                        <div className="col-sm-4 mb-3">

                            <label className="form-label">ชื่อ</label>
                            <input className="form-control form-control-sm"  value={name} onChange={event=>set_name( event.target.value )}  />

                        </div>

                        <div className="col-sm-4 mb-3">

                            <label className="form-label">นามสกุล</label>
                            <input className="form-control form-control-sm" value={family_name} onChange={event=>set_family_name(event.target.value)} />
                            
                        </div>

                        <div className="col-sm-4 mb-3">

                            <label className="form-label">เลขบัตรประชาชน ( กรอกเฉพาะตัวเลข ) </label>
                            <input className="form-control form-control-sm" value={citizen_id} 
                            onChange={event=>{
                                if(event.target.value.length > 13){ return; }
                                set_citizen_id(check_int( event.target.value ));
                            }} />
                            
                        </div>

                        <div className="col-sm-4 mb-3">

                            <label className="form-label">อีเมล</label>
                            <input className="form-control form-control-sm" value={email} 
                                onChange={event=>set_email( eng_only( event.target.value ) )} 
                            />
                            
                        </div>

                        <div className="col-sm-3 mb-3">

                            <label className="form-label">วันเกิด</label>
                            <input className="form-control form-control-sm" id="birth_date" />
                            
                        </div>

                        <div className="col-sm-1 mb-3">

                            <label className="form-label">อายุ</label>
                            <input className="form-control form-control-sm" disabled={true} value={age} />
                            
                        </div>

                        <div className="col-sm-4 mb-3">
                            <label className="form-label">ชนิดกีฬา <span className="text-danger">กรุณาเลือก คำนำหน้า และวันเกิดก่อน ก่อน</span> </label>
                            <select className="form-select form-select-sm" value={sport_id} onChange={event=>set_sport_id(event.target.value)}>
                                <option value=""></option>
                                {sport_display.map(item=>
                                    <option value={item.sport_id}>{getSportDisplay(item)}
                                    </option>    
                                )}
                            </select>
                        </div>

                        <div className="col-sm-4 mb-3">

                            <label className="form-label">สมัครเข้าเรียนชั้น </label>
                            <select className="form-select form-select-sm"  value={edu_level_id} onChange={event=>{set_edu_level_id(event.target.value)}} >
                                    <option value=""></option>
                                    {edu_level.map(item=>
                                        <option value={item.edu_level_id} >{item.edu_level_name}</option>    
                                    )}
                            </select>
                            
                        </div>

                        <div className="col-sm-4 mb-3">

                            <label className="form-label">เบอร์โทรศัพท์มือถือนักเรียน ( กรอกเฉพาะตัวเลข )</label>
                            <input className="form-control form-control-sm" value={telephone_number} onChange={event=>{
                                
                                if(event.target.value.length > 10){ return; }
                                if(event.target.value.substr(0,1).toString()!="0"){ return; }

                                set_telephone_number(check_int( event.target.value ));

                                }}    />
                            
                        </div>

                        <div className="col-sm-4 mb-3">

                            <label className="form-label">เบอร์โทรศัพท์ ผู้ปกครอง ( กรอกเฉพาะตัวเลข )</label>
                            <input className="form-control form-control-sm" value={telephone_number_parent} onChange={event=>{
                                if(event.target.value.length > 10){ return; }
                                if(event.target.value.substr(0,1).toString()!="0"){ return; }

                                set_telephone_number_parent(check_int( event.target.value ));

                            }}    />
                            
                        </div>

                       
                        <div className="col-sm-4 mb-3">

                            <label className="form-label">โรงเรียนที่ศึกษาอยู่</label>
                            <input className="form-control form-control-sm" value={school} onChange={event=>set_school(event.target.value)}    />
                            
                        </div>

                        <div className="col-sm-4 mb-3">

                            <label className="form-label">จังหวัดที่โรงเรียนตั้งอยู่</label>
                            <select className="form-select form-select-sm" value={province_id} onChange={event=>set_province_id(event.target.value)} >
                                <option value="" ></option>
                                {province.map(item=>
                                    <option value={item.province_id}>{item.province_name}</option>    
                                )}
                            </select>

                        </div>

                       

                        <div className="col-sm-8 mb-3">
                            <label className="form-label">ที่อยู่ปัจจุบัน</label>
                            <input className="form-control form-control-sm"  value={address} onChange={event=>set_address(event.target.value)} />
                        </div>
                        <div className="col-sm-4">   
                        </div>

                        <div className="col-sm-3">
                            <Upload label="อัปโหลดรูปนักเรียน" picture_type="รูปนักเรียน" upload_index="1" refresh={(file_name)=>{
                                set_applicant_picture(file_name);
                            }} />     
                        </div>
                        <div className="col-sm-3">
                            {(applicant_picture!="")?
                                <>
                                    <img style={{width:"80px",height:"80px","object-fit": "cover","object-position": "100% 0"}} src={server_url()+applicant_picture+"?t="+(new Date()).getTime()}  className="rounded float-start" />&nbsp;
                                    <div className="text-success" >&nbsp;<i className="fa fa-check"></i>&nbsp;ใส่รูปภาพแล้ว</div>
                                    <div><a className="btn btn-link btn-sm" href={server_url()+applicant_picture+"?t="+(new Date()).getTime()} target="_blank" ><i className="fa fa-search"></i>&nbsp;ดูรูป</a></div>

                                </>
                            :null}
                        </div>
                        <div className="col-sm-6">
                        </div>

                        <div className="col-sm-3">
                            <Upload label="อัปโหลดสำเนาบัตรประชาชน" picture_type="สำเนาบัตรประชาชน" upload_index="2"  refresh={(file_name)=>{
                                set_citizen_picture(file_name);
                            }} />     
                        </div>
                        <div className="col-sm-3">
                            {(citizen_picture!="")?
                                <>
                                    <img style={{width:"80px",height:"80px","object-fit": "cover","object-position": "100% 0"}} src={server_url()+citizen_picture+"?t="+(new Date()).getTime()}  className="rounded float-start mt-2" />&nbsp;
                                    <div className="text-success" >&nbsp;<i className="fa fa-check"></i>&nbsp;ใส่รูปภาพแล้ว</div>
                                    <div><a className="btn btn-link btn-sm" href={server_url()+citizen_picture+"?t="+(new Date()).getTime()} target="_blank" ><i className="fa fa-search"></i>&nbsp;ดูรูป</a></div>

                                </>
                            :null}
                        </div>
                        <div className="col-sm-6">
                            
                        </div>

                        <div className="col-sm-3">
                            <Upload label="อัปโหลดสำเนาสูติบัตร" picture_type="สำเนาสูติบัตร" upload_index="3" refresh={(file_name)=>{
                                
                                import(file_name);
                            }} />     
                        </div>
                        <div className="col-sm-3">
                            {(birth_certificate_picture!="")?
                                <>
                                    <img style={{width:"80px",height:"80px","object-fit": "cover","object-position": "100% 0"}} src={server_url()+birth_certificate_picture+"?t="+(new Date()).getTime()}  className="rounded float-start mt-2" />&nbsp;
                                    <div className="text-success" >&nbsp;<i className="fa fa-check"></i>&nbsp;ใส่รูปภาพแล้ว</div>
                                    <div><a className="btn btn-link btn-sm" href={server_url()+birth_certificate_picture} target="_blank" ><i className="fa fa-search"></i>&nbsp;ดูรูป</a></div>

                                </>
                                :null}
                        </div>
                        <div className="col-sm-6">
                            
                        </div>



                        </div>


                <div className="mt-3">
                <div className="text-danger mb-3">{err}</div>
                <button className="btn btn-sm btn-success" type="button"
                             data-loading-text="กำลังบันทึก"
                             onClick={event=>{ 
                                 save(event);
                             }}
                ><i className="fa fa-check"></i> บันทึกข้อมูลผู้สมัคร</button>&nbsp;
                <button className="btn btn-sm btn-secondary" type="button"
                            onClick={event=>{ history.goBack();  }}
                ><i className="fa fa-sign-out-alt"></i> กลับ</button>&nbsp;
            </div>
            
                </div>
            </div>

            

    </div>);

}

export default forwardRef(RegisterationForm);