
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import { useEffect,useRef } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router';
import { server_url } from '../config';
import  ConfirmPopup  from '../confirm_popup';
import Popup from '../popup';
import "@fortawesome/fontawesome-free/css/all.min.css";


const Template =(props)=>{

    let history=useHistory();
    const confirm=useRef();
    const notification=useRef();

    const show_confirm=(detail,click)=>{
        confirm.current.open(detail,()=>{
           click();
        });
    }



    const show_notification=(header,detail)=>{
        notification.current.open(header,detail);
    }

    useEffect(async ()=>{

        //console.log(props);

        // if(document.URL.indexOf("localhost")==-1)
        // {
        //     if(document.URL.indexOf("https://")==-1)
        //     {
        //         window.location=server_url();
        //         return;
        //     }
        // }

        if(localStorage["token"]==null)
        {
            localStorage["token"]="";
        }

        let formData=new FormData();
        formData.append("mode","check");
        formData.append("token",localStorage["token"]);

        let response=await axios.post(server_url()+"api/authentication.php",formData);
      
        if(response.data.error!=null)
        {
            history.replace("/admin");
        }

        window.show_confirm=show_confirm;
        window.show_notification=show_notification;
        
        
        var mybutton = document.getElementById("go_to_top_page");
        window.onscroll = function() {scrollFunction()};
        function scrollFunction() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                mybutton.style.display = "block";
            } else {
                mybutton.style.display = "none";
            }
        }

     

    },[]);

    

    return (<div>
        
        <ConfirmPopup ref={confirm} />
        <Popup ref={notification} />

        <nav className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top">
            <div className="container-fluid">
                <a className="navbar-brand" href="#/admin/home">ระบบการรับสมัครนักเรียนโรงเรียนกีฬากรุงเทพมหานคร</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse text-right" id="navbarSupportedContent">
                   
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">

                        <li className="nav-item">
                            <a className="nav-link active" href="#/admin/registeration"  >ข้อมูลผู้สมัคร</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" href="#/admin/sport" >กีฬาที่รับสมัคร</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" href="#/admin/excel"  >Download Excel</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" href="#/admin/pdf"  >Download PDF</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" href="#/admin/schedule" >กำหนดการ</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" href="#/admin/document_file" >เอกสาร</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" href="#/admin/apply_date" >วันที่เปิด-ปิด การรับสมัคร</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" style={{ cursor: "pointer"}} onClick={event=>{
                                confirm.current.open("ต้องการออกจากระบบ ?",()=>{
                                    
                                    localStorage["token"]="";
                                    history.replace("/");
                                });
                            }}>ออกจากระบบ</a>
                        </li>
                        
                    </ul>
                
                </div>
            </div>
        </nav>

        <div className="container mt-5 mb-5" >
            <br />
            <br />
            {props.children}             
        </div>

        
        <button  id="go_to_top_page" className="btn btn-primary btn-sm"
            onClick={event=>{
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }}
            type="button" 
            style={{ "display": "none",
                "position": "fixed",
                "bottom": "20px",
                "right": "30px",
                "z-index": "9999",
                "font-size": "18px",
            }}
        ><i className="fa fa-chevron-up"></i></button>

    </div>);
};

export default Template;