import 'bootstrap/dist/css/bootstrap.min.css';//import bootstrap
import 'bootstrap/dist/js/bootstrap.bundle.js';
import { useEffect,useState } from "react";
import axios from 'axios';
import {server_url} from "../config";
import { useHistory,useParams } from "react-router";
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';

const ExportPdf=()=>{


    const history=useHistory();
    const {edu_year,status_id,sport_id}  = useParams();

    const [loading,set_loading] = useState(true);
    const [transaction_type_name,set_transaction_type_name] = useState("");
    const [data_table,set_data_table] =useState([]);
    const [sport_name,set_sport_name]=useState("");

    const [note,setNote]=useState("");

    const [startPrint,setStartPrint]=useState(false);
    const [mainTable,setMainTable]=useState([]);
   

    useEffect(async ()=>{

        let form_data=new FormData();//หาสถานะการสมัครที่มีอยู่ใน database
        form_data.append("mode","get_transaction_type");
        let response=await axios.post(server_url()+"api/registeration.php",form_data);
        let transaction_type_name=response.data.find(item=>{ return item.transaction_type_id=status_id }).transaction_type_name
        set_transaction_type_name(transaction_type_name);

        //หาชนิดกีฬา
        form_data=new FormData();//หาสถานะการสมัครที่มีอยู่ใน database
        form_data.append("mode","get_sport_name");
        form_data.append("sport_id",sport_id);
        response=await axios.post(server_url()+"api/registeration.php",form_data);
        set_sport_name(response.data.sport_name);
        let sportName=response.data.sport_name;

        form_data=new FormData();
        form_data.append("mode","download");//เพื่อให้ "api/registeration.php" เตรียมข้อมูลของผู้สมัครเพื่อ download
        form_data.append("register_year",edu_year);
        form_data.append("transaction_type_id",status_id);
        form_data.append("sport_id",sport_id);
        form_data.append("token",localStorage["token"]);//ส่ง token เพื่อ แสดงว่า login แล้ว

        axios.post(server_url()+"api/registeration.php",form_data).then(response=>{

            //ชุดคำสั่งเมื่อ "api/registeration.php" ส่งข้อมูลผู้สมัครกลับมา

            //console.log(response);

            if(typeof(response.data)!="object")
            {

                document.write(response.data);
                return
            }

          
            if(response.data.length==0)//ถ้าไม่มีข้อมูล 
            {
                
            }

            
             let temp=response.data[response.data.length-1];
             //-----------เพิ่มแถวข้อมูลสำหรับ  Test----------
            // for(let i=0;i<200;i++)
            // {
            //     response.data.push(temp);
            // }
            
            //-----จัดหน้า----
            temp=response.data;
            let tempMainTable=[];
            let subTable=[];
            let count=0;
            temp.map((item,index)=>{

                if( count==0 && index!=0 )
                {
                    tempMainTable.push({
                        "header":(<div className='mt-2 text-center'>
                            <div>ประกาศรายชื่อผู้{transaction_type_name}</div> 
                            <div>เป็นนักเรียนศูนย์ฝึกกีฬาเยาวชน (โรงเรียนกีฬากรุงเทพมหานคร) ประจำปีการศึกษา {parseInt(edu_year)+543}</div>
                            <div>กีฬา {sportName}</div>
                        </div>),
                        "subTable":[...subTable]
                    });
                    subTable=[];
                }

                item.rowNum=index+1;
                subTable.push({...item});

                count++;
                if(count==32)
                {
                    count=0;
                }

            });

            if(subTable.length!=0)
            {
                tempMainTable.push({
                    "header":(<div className='mt-2 text-center'>
                        <div>ประกาศรายชื่อผู้{transaction_type_name}</div> 
                        <div>เป็นนักเรียนศูนย์ฝึกกีฬาเยาวชน (โรงเรียนกีฬากรุงเทพมหานคร) ประจำปีการศึกษา {parseInt(edu_year)+543}</div>
                        <div>กีฬา {sportName}</div>
                    </div>),
                    "subTable":subTable
                });
            }

            //console.log(tempMainTable);
            
            setMainTable(tempMainTable);
            //set_data_table(response.data);

            set_loading(false);


        });



    },[]);

    const printNote=()=>{

        let str=note;
        let strArr=str.split(/\r?\n/);
       
        if(mainTable.length!=0)
        {
            
            let temp=[...mainTable];

            temp.map(item=>{
                for(let i=0;i<strArr.length;i++)
                {

                    item.subTable[i].note=strArr[i];
                }
            })

            

            setMainTable(temp);

        }

        setStartPrint(true);
     
    }

    useEffect(()=>{

        if(startPrint)
        {
            setTimeout(()=>{
                window.print();
            },500);
            
        }

    },[startPrint]);

    const openPopup=()=>{

        var myModal = new bootstrap.Modal(document.getElementById('note_modal'), {});
        myModal.show();
    }

    return (<div >


        <div className="modal fade" id="note_modal" tabIndex="-1" aria-labelledby="notification_popup_header" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">หมายเหตุ</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center">
                                

                                <textarea className='form-control form-control-sm' style={{height:'200px'}} value={note}
                                    onChange={event=>setNote(event.target.value)}
                                ></textarea>

                                <div className='pt-2'>
                                    <button type="button" className="btn btn-sm btn-primary" data-bs-dismiss="modal"
                                        onClick={event=>{
                                            setNote("");
                                            printNote();
                                        }}
                                    >ตกลง</button>
                                </div>
                                
                    
                    </div>
                    
                    </div>
                </div>
            </div>

        {loading?
            <div className='alert alert-warning mt-2' >กำลังโหลด.....</div>
        :null}

            {(!startPrint)?<div className='text-center'>
                <button className='btn btn-primary btn-sm mt-2' type="button" onClick={event=>{ openPopup() }}>
                    <i className='fa fa-print'></i>&nbsp;พิมพ์
                </button>
            </div>
            :null}

            {mainTable.map(item=><>

                    {item.header}

                    <table className='mt-2' style={{width:"100%","border-collapse": "collapse","border": "1px solid"}}>
            
                        <tr>
                            <td style={{"border": "1px solid"}} className="text-center" >ลำดับ</td>
                            <td style={{"border": "1px solid"}} className="text-center">เลขประจำตัว</td>
                            <td style={{"border": "1px solid"}} className="text-center">ชื่อ - นามสกุล</td>
                            <td style={{"border": "1px solid"}} className="text-center">หมายเหตุ</td>
                        </tr>

                        {item.subTable.map((row)=>
                            <tr>
                                <td style={{"border": "1px solid"}} className="text-center">{row.rowNum}</td>
                                <td style={{"border": "1px solid"}} className="text-center">{row.registeration_code}</td>
                                <td style={{"border": "1px solid"}} >{row.name} {row.family_name}</td>
                                <td style={{"border": "1px solid"}} className="text-center" >{row.note!=null?row.note:null}</td>
                            </tr>   
                        )}

                    </table>

                
            </>)}

        
        
        {/* <div className='mt-2 text-center'>
            <div>ประกาศรายชื่อผู้{transaction_type_name}</div> 
            <div>เป็นนักเรียนศูนย์ฝึกกีฬาเยาวชน (โรงเรียนกีฬากรุงเทพมหานคร) ประจำปีการศึกษา {parseInt(edu_year)+543}</div>
            <div>กีฬา {sport_name}</div>
        </div>

        <table className='mt-2' style={{width:"100%","border-collapse": "collapse","border": "1px solid"}}>
            
            <tr>
                <td style={{"border": "1px solid"}} className="text-center" >ลำดับ</td>
                <td style={{"border": "1px solid"}} className="text-center">เลขประจำตัว</td>
                <td style={{"border": "1px solid"}} className="text-center">ชื่อ - นามสกุล</td>
                <td style={{"border": "1px solid"}} className="text-center">หมายเหตุ</td>
            </tr>

            {data_table.map((row,index)=>
                <tr>
                    <td style={{"border": "1px solid"}} className="text-center">{index+1}</td>
                    <td style={{"border": "1px solid"}} className="text-center">{row.registeration_code}</td>
                    <td style={{"border": "1px solid"}} >{row.name} {row.family_name}</td>
                    <td style={{"border": "1px solid"}} className="text-center" >{row.note!=null?row.note:null}</td>
                </tr>   
            )}

        </table> */}


    </div>);

}


export default ExportPdf;