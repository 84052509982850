import { useEffect, useState } from "react";
import Template from "./template";//เมนู ด้านบน footer
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';//ให้กรอกข้อความก่อนสมัคร
import { eng_only, loading_button, reset_button ,check_int } from "./lib";// eng_only ให้กรอก เฉพาะ ภาษาอังกฤษ  loading_button แสดงปุ่ม ให้เป็นสถานะ Load reset_button ให้ปุ่มกลับเป็น สถานะปกติ check_int ให้กรอก เฉพาะตัวเลข
import { picker_date } from "./picker_date"; //ตัวเลือกปฎิทิน 
import axios from "axios";//สำหรับเรียกไฟล์ PHP ที่เชื่อมกับ database
import { server_url } from "./config";//ระบุว่ากำลังต่อกับ server domain อะไร
import { check_apply_date } from "./check_apply_date";//ดูว่าอยู่ในช่วงการรับสมัคร หรือเปล่า
import Upload from "./upload";//ทำ Upload รูป สมัคร รูปบัตรประชาชน

const Register=()=>{


    const [err,set_err]=useState("");//Error แจ้งเตือนกรณีกรอกไม่ครบ
    const [cap,set_cap]=useState("");//เก็บ Capcha ที่ user กรอก
    const [age,set_age]=useState("");//อายุ
    const [province,set_province]=useState([]);//จังหวัดที่มีอยู่ใน database
    const [sport,set_sport]=useState([]);//กีฬาที่มีอยู่ใน database
    const [edu_level,set_edu_level]=useState([]);//ระดับการศึกษาที่มีอยู่ใน database
    const [sport_display,set_sport_display]=useState([]);//กีฬาที่สอดคล้องกับอายุ และเพศ

    const [title,set_title]=useState("");//คำนำหน้าชื่อ
    const [name,set_name]=useState("");//ชื่อ
    const [family_name,set_family_name]=useState("");//นามสกุล
    const [citizen_id,set_citizen_id]=useState("");//เลขบัตรประชาชน
    const [telephone_number,set_telephone_number]=useState("");//เบอร์โทรศัพท์
    const [telephone_number_parent,set_telephone_number_parent]=useState("");//เบอร์โทรศัพท์ ผู้ปกครอง
	const [school,set_school]=useState("");//โรงเรียน
    const [province_id,set_province_id]=useState("");//รหัสจังหวัดที่เลือก
    const [address,set_address]=useState("");//ที่อยู่
    const [sport_id,set_sport_id]=useState("");//กีฬาที่เลือกสมัคร
    const [edu_level_id,set_edu_level_id]=useState("");//รหัสระดับการศึกษา
    const [email,set_email]=useState("");//email

    const [applicant_picture,set_applicant_picture]=useState("");//ชื่อไฟล์ผู้สมัคร
    const [citizen_picture,set_citizen_picture]=useState("");//ชื่อไฟล์ รูปบัตรประชาชน
    const [birth_certificate_picture,set_birth_certificate_picture]=useState("");//ชื่อไฟล์ สูจิบัตร

    const [success,set_success]=useState(false);//สถานะว่าสมัครเสร็จ แล้วหรือยัง
    const [success_response,set_success_response]=useState(false);//ข้อมูลของผู้สมัครหลังจากบันทึกลงฐานข้อมูล แล้วเพื่อ Download PDF

    const [can_apply, set_can_apply] = useState("");//ถ้าเป็น 0 แสดงว่าไม่อยู่ในช่วงการสมัคร ถ้าเป็น 1 แสดงว่าอยู่ในช่วงการสมัคร

    const [edu_level_disply,set_edu_level_disply] = useState([]);

    //เลือก กีฬา แล้ว เปลี่ยนชั้นเรียน
    useEffect(()=>{
        
        set_edu_level_id("");
        set_edu_level_disply([]);

        if(sport_id!="")
        {
            let sportObj = sport.find((item)=>{ return item.sport_id==sport_id; });

            if( sportObj  && sportObj.min_edu_level && sportObj.max_edu_level)
            {
               let eduArr=[];

               edu_level.map(item=>{
                    if(  
                        ( item.edu_level_id >= sportObj.min_edu_level.edu_level_id  ) &&
                        ( item.edu_level_id <= sportObj.max_edu_level.edu_level_id )
                    )
                    {
                        eduArr.push(item);
                    }

               });

               set_edu_level_disply([...eduArr]);

            }
            else
            {
                //สมัครได้ทุกระดับชั้น
                set_edu_level_disply([...edu_level]);
            }
            
        }


    },[sport_id]);

    const get_sport=()=>{//หาว่าอายุ และเพศของผู้สมัครสามารถเล่นกีฬาอะไรได้บ้าง 

        set_sport_id("");
        set_sport_display([]);

        set_edu_level_id("");
        set_edu_level_disply([]);
        

        if( age!="" && title!="" /*&& edu_level_id!=""*/  )
        {
          
            //หาว่าอายุเพศ  ระดับชั้น สามารถ สมัครอะไรได้บ้าง
            let out_put=[];
            let temp=[...sport];
            temp.map(item=>{

                if(item.gender=="" && item.age1=="0" && item.age2=="0")
                {
                    out_put.push(item);
                }
                else{

                        let flag1=0;
                        let flag2=0;
                        //let flag3=0;
                        
                        if(item.gender!="")
                        {
                            if(item.gender=="ชาย")
                            {
                                if(title=="นาย" || title=="เด็กชาย")
                                {
                                    flag1=1;
                                }
                                

                                //item.sport_name+=" ( ชาย ) ";

                            }   

                            if(item.gender=="หญิง")
                            {
                                //console.log(item.gender);
                                if(title=="นางสาว" || title=="เด็กหญิง")
                                {
                                    flag1=1;
                                }

                                //item.sport_name+=" ( หญิง ) ";
                            }
                            
                        }
                        else
                        {
                            flag1=1;
                        }
                        
                        if( item.age1!="0" && item.age2!="0")
                        {
                            //console.log(item.age1);
                            if( 
                                parseInt(item.age1) <= parseInt(age) &&
                                parseInt(age) <=  parseInt(item.age2)  
                            )
                            {
                                
                                flag2=1;
                            
                                
                            }
                            

                            //item.sport_name=item.sport_name+" ( อายุ "+item.age1+" ปี ถึง "+item.age2+" ปี )"
                        }
                        else
                        {
                            flag2=1;
                        }

                        // if( item.min_edu_level!=null && item.max_edu_level!=null)
                        // {
                        //     //console.log(item.age1);
                        //     if( 
                        //         parseInt(item.min_edu_level.edu_level_id) <= parseInt(edu_level_id) &&
                        //         parseInt(edu_level_id) <=  parseInt(item.max_edu_level.edu_level_id)  
                        //     )
                        //     {
                        //         flag3=1;
                        //     }
                        // }
                        // else
                        // {
                        //     flag3=1;
                        // }
                        
                        //if(flag1==1 && flag2==1 && flag3==1)
                        if(flag1==1 && flag2==1)
                        {
                            out_put.push(item);
                        }
                }

            });

            set_sport_display(out_put);

        }
        

        

    }

    useEffect(()=>{  get_sport(); },[title,age])//ถ้าผู้ใช้เปลี่ยนคำนำหน้า หรืออายุให้ไปหา ว่าสามารถสมัครกีฬาอะไรได้บ้าง

    //-----หาอายุจากวันเกิด-----
    const get_age=(DOB)=>
    {
        if(DOB=="")
        {
            return "";
        }


        //----นับ 1 มกราคม------
        let arr=DOB.split("-");
        arr[0]=arr[0];
        arr[1]="01";
        arr[2]="01";
        DOB=arr[0]+"-"+arr[1]+"-"+arr[2];

            var today = new Date();
            var birthDate = new Date(DOB);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age = age - 1;
            }

            return age;
    }

    //function แรกที่ทำเมื่อ Load component
    useEffect(()=>{
         
        loadCaptchaEnginge(6,'white','green','lower');//สร้าง CapCha
        //สร้างตัวเลือก ปฎิทิน โดยปฎิทินจะไปอยู่ที่ Element ที่มี id ที่มีชื่อว่า "birth_date"
        picker_date(document.getElementById("birth_date"),{year_range:"-30:0",onchange:(date)=>{
            //ถ้าวันที่เลือกใน ปฎิทิน ไม่เป็นค่าว่าง จะไปหาอายุ
            if(date=="")
            {
                set_age("");
                return;
            }
           
            let date_arr=date.split("/");//เปลี่ยนวันที่ในปฎิทินจาก วันเดือนปี เป็น ปี(ค.ศ.)-เดือน-วัน 
            date=(date_arr[2]-543)+"-"+date_arr[1]+"-"+date_arr[0];
            set_age(get_age(date));//หาอายุจากวันเกิด และเปลี่ยน state อายุ

        }});

        (async function(){

            //ดึงข้อมูลจังหวัดที่อยู่ใน ฐานข้อมูล
            let formData=new FormData();
            formData.append("mode","get_province");
            
            let response=await axios.post(server_url()+"api/register.php",formData);
            
            set_province(response.data);

            //ดึงข้อมูลกีฬาที่อยู่ในฐานข้อมูล
            formData=new FormData();
            formData.append("mode","get_sport");

            response=await axios.post(server_url()+"api/register.php",formData);
            set_sport(response.data);

            //ดึงข้อมูลระดับการศึกษาที่อยู่ในฐานข้อมูล
            formData= new FormData();
            formData.append("mode","get_edu_level");

            response=await axios.post(server_url()+"api/register.php",formData);
            set_edu_level(response.data);

            //ดูว่าอยู่ในช่วงการรับสมัคร หรือไม่
            let check_result=await check_apply_date();
            set_can_apply(check_result);//0 คือ ไม่อยู่ในช่วงการรับสมัคร 1 คืออยู่ในช่วงการรับสมัคร

        })()


    },[]);

    const save=async (event)=>{

        if (!validateCaptcha(cap)==true) { // validateCaptcha คือ ดูว่า capcha ที่ส่งมาถูกต้องหรือไม่
            
            set_err("รหัสไม่ถูกต้อง");//ถ้าไม่ถูกจะแจ้งข้อความเตือน
            set_cap("");//cler ค่า textbox capcha
            return;
        }

        if(
            title=="" || //ต้องเลือกคำนำหน้าชื่อ
            name=="" || //ต้องกรอกชื่อ
            family_name=="" || //ต้องกรอกนามสกุล
            citizen_id=="" || //ต้องกรอกเลขประจำตัวประชาชน
            telephone_number=="" || //ต้องกรอกเบอร์โทรศัพท์นักเรียน
            telephone_number_parent=="" || //ต้องกรอกเบอร์โทรศัพท์ผู้ปกครอง
            school=="" || //ต้องกรอกโรงเรียน
            province_id=="" || //ต้องเลือกจังหวัด
            address=="" || //ต้องกรอกที่อยู่
            sport_id=="" || //ต้องเลือก กีฬา ที่สมัคร
            email=="" || //ต้องกรอก email
            applicant_picture=="" || //ต้องใส่รูปผู้สมัคร
            citizen_picture=="" || //ต้องใส่รูปบัตรประชาชน
            birth_certificate_picture=="" || //ต้องใส่รูปสูจิบัตร
            edu_level_id=="" //ต้องเลือกระดับการศึกษา
        )
        {
            set_err("กรุณากรอกข้อมูลให้ครบ");//ถ้ากรอกไม่ครบจะมีข้อความเตือน
            return;
        }

        if(citizen_id.length!=13)//เลขประจำตัวประชาชนต้องมี 13 หลัก
        {
            set_err("เลขที่ประจำตัวประชาชนไม่ครบ 13 หลัก");
            return;
        }

        if(telephone_number.length!=10)//เบอร์โทรศัพท์ต้องมี 10 หลัก
        {
            set_err("เบอร์โทรศัพท์ไม่ครบ 10 หลัก");
            return;
        }

        if( email.indexOf("@")==-1 && email.indexOf(".")==-1 ) //email ต้องมี @ แลต้องมี .
        {
            set_err("รูปแบบ Email ไม่ถูกต้อง");
            return;
        }

        //ถ้ากรอกครบถูกต้องแล้ว ส่งข้อมูลไปบันทึกที่ register.php

        let form_data=new FormData();
        form_data.append("mode","save");//ระบุ mode ว่าต้องการ save
        //ส่งข้อมูลวันเกิด
        form_data.append("birth_date",document.getElementById("birth_date").value);
        
        form_data.append("title",title);//ส่งข้อมูลคำนำหน้าชื่อ
        form_data.append("name",name);//ส่งข่อมูลชื่อ
        form_data.append("family_name",family_name);//ส่งข้อมูลนามสกุล
        form_data.append("citizen_id",citizen_id);//ส่งข้อมูล เลขบัตรประชาชน
        form_data.append("telephone_number",telephone_number);//ส่งข้อมูลเบอร์โทรศัพท์ นักเรียน
        form_data.append("telephone_number_parent",telephone_number_parent);//ส่งข้อมูลเบอร์โทรศัพท์ผู้ปกครอง
        form_data.append("school",school);//ส่งข้อมูลโรงเรียน
        form_data.append("province_id",province_id);//ส่งข้อมูลจังหวัด
        form_data.append("address",address);//ส่งข้อมูลที่อยู่
        form_data.append("sport_id",sport_id);//ส่งข้อมูลรหัสกีฬาที่สมัคร
        form_data.append("email",email);//ส่งข้อมูล Email
        form_data.append("applicant_picture",applicant_picture);//ส่งข้อมูลรูปผู้สมัคร
        form_data.append("citizen_picture",citizen_picture);//ส่งข้อมูลรูปบัตรประชาชน
        form_data.append("birth_certificate_picture",birth_certificate_picture);//ส่งข้อมูลสูจิบัตร
        form_data.append("edu_level_id",edu_level_id)//ส่งข้อมูลระดับการศึกษาที่สมัคร


        set_err("");//clear ข้อความแจ้งเตือน
        //เปลี่ยนสถานะปุ่มเป็นกำลังโหลด
        let b=event.currentTarget;
        loading_button(b);
        
        //ตัวแปรเก็บข้อมูลที่ส่งมาจาก server หลังบันทึกเสร็จ
        let response="";
        let json="";
        try {
            //"api/register.php" ส่งข้อมูลไปบันทึกที่ไฟล์ response
            response=await axios.post(server_url()+"api/register.php",form_data);
            //console.log(response);
            reset_button(b);//เปลี่ยนสถานะปุ่มเป็นปกติ
            json=response.data;


            if(json.error!=null)//ถ้ามี error จะแสดงข้อความ Error เช่น ข้อมูลซ้ำ
            {
                //console.log(response);
                set_err(response.data.error);
                return;
            }

        } catch (err) {

            set_err(response.data.error);
            return;
        }

        //console.log(response.data);
        set_success(true);//เปลี่ยนสถานะหน้าเว็บเป็นลงทะเบียนแล้ว
        set_success_response(response.data);//เก็บ object success ไว้ในตัวแปร  success_response ( มี user_key เพื่อสร้าง pdf )
        //window.open(server_url()+"pdf/index.php?user_key="+response.data.user_key,"_blank");
        //window.open(server_url()+"pdf/index.php?user_key="+response.data.user_key,"_blank");

    }

    if(can_apply!="1" && can_apply!="")//ไม่อยู่ในช่วงการรับสมัคร จะ ไม่ render form รับสมัคร
    {
        return (<Template  header="img/header.png">

        </Template>);
    }

    return (<Template  header="img/header.png"> {/*------ เมนูด้านบน และ footer ด้านล่าง -----*/}

        <h4>สมัครเรียน</h4>
        <hr />
      

        {/* <div className="text-primary mb-2"><u>สอบถามปัญหาการใช้งานระบบการสมัครเรียนได้ที่ 089-492-5135</u></div> */}
        {(success)?<>
            {/*-----หน้าเว็บที่แสดงเมื่อสมัครเสร็จแล้ว----- */}
                <h5 className="text-center text-success">

                    <i className="fa fa-check"></i> ลงชื่อสมัครเรียนแล้ว 

                </h5>
                <p className="text-center">
                    <br />{/* ------ success_response จะส่ง user_key ของผู้สมัครมา------ */}
                    {/* ------ ปุ่มสำหรับ Download PDF เมื่อ กดแล้ว  จะไปเรียกไฟล์ "pdf/index.php?user_key=" และส่ง user key ------ */}
                    <button className="btn btn-sm btn-danger mt-2" type="button"
                        onClick={event=>{ window.open(server_url()+"pdf/index.php?user_key="+success_response.user_key,"_blank")/* "_blank" เพื่อให้เปิด tab ใหม่ */ }}
                    >
                        <i className="fa fa-download"></i> &nbsp;
                        <i className="fa fa-file"></i> Downlaod ใบสมัคร
                    </button>
                    <iframe style={{width:"0px",height:"0px"}} src={server_url()+"pdf/index.php?user_key="+success_response.user_key}></iframe>
                    {/* <br />
                    <br />
                    ระบบได้ส่งข้อมูลใบสมัครใบที่ Email <b>{success_response.email}</b> แล้ว<br />
                    <u className="text-danger">หากไม่พบกรุณาตรวจสอบที่ ถังขยะ (Junk Mail)</u> */}
                    
                </p>
            </>
        :<>
                
                <div className="row">
                    {/*-----หน้าเว็บฟอร์มการสมัคร ( สถานะ ในตัวแปร success ไม่เท่ากับ true )----- */}

                        <div className="col-sm-4 mb-3">

                                <label className="form-label">คำนำหน้า</label>
                                <select className="form-select form-select-sm"  value={title} onChange={event=>set_title(event.target.value)} >
                                    <option value=""></option>
                                    <option value="นาย">นาย</option>
                                    <option value="นางสาว">นางสาว</option>
                                    <option value="เด็กชาย">เด็กชาย</option>
                                    <option value="เด็กหญิง">เด็กหญิง</option>
                                </select>
                        </div>

                        <div className="col-sm-4 mb-3">

                            <label className="form-label">ชื่อ</label>
                            <input className="form-control form-control-sm"  value={name} onChange={event=>set_name( event.target.value )}  />

                        </div>

                        <div className="col-sm-4 mb-3">

                            <label className="form-label">นามสกุล</label>
                            <input className="form-control form-control-sm" value={family_name} onChange={event=>set_family_name(event.target.value)} />
                            
                        </div>

                        <div className="col-sm-4 mb-3">

                            <label className="form-label">เลขบัตรประชาชน ( กรอกเฉพาะตัวเลข ) </label>
                            <input className="form-control form-control-sm" value={citizen_id} 
                            onChange={event=>{
                                if(event.target.value.length > 13){ return; }//ห้ามออกเกิน 13 หลัก
                                set_citizen_id(check_int( event.target.value ));//เลขบัตรประชาชน ให้กรอกใด้เฉพาะตัวเลข
                            }} />
                            
                        </div>

                        <div className="col-sm-4 mb-3">

                            <label className="form-label">อีเมล</label>
                            <input className="form-control form-control-sm" value={email} 
                                onChange={event=>set_email( eng_only( event.target.value ) ) /*------- eng_only ให้กรอกให้เฉพาะภาษาอังกฤษ -----*/} 
                            />
                            
                        </div>

                        <div className="col-sm-3 mb-3">

                            <label className="form-label">วันเกิด</label>
                            <input className="form-control form-control-sm" id="birth_date" />
                            
                        </div>

                        <div className="col-sm-1 mb-3">

                            <label className="form-label">อายุ</label>
                            <input className="form-control form-control-sm" disabled={true} value={age} />
                            
                        </div>

                        <div className="col-sm-4 mb-3">
                            <label className="form-label">ชนิดกีฬา <span className="text-danger">กรุณาเลือก คำนำหน้า และวันเกิดก่อน </span> </label>
                            <select className="form-select form-select-sm" value={sport_id} onChange={event=>set_sport_id(event.target.value)}>
                                <option value=""></option>
                                {sport_display.map(item=>
                                    <option value={item.sport_id}>
                                        {item.sport_name} 
                                        {item.gender!=""?" ( "+item.gender+" )":null}
                                        {item.age1!="0"?" อายุ "+item.age1+" ถึง "+item.age2+" ปี ":null}
                                    </option>    
                                )}
                            </select>
                        </div>

                        <div className="col-sm-4 mb-3">

                            <label className="form-label">สมัครเข้าเรียนชั้น <span className="text-danger">กรุณาเลือก ชนิดกีฬาก่อน</span></label>
                            <select className="form-select form-select-sm"  value={edu_level_id} onChange={event=>{set_edu_level_id(event.target.value)}} >
                                    <option value=""></option>
                                    {edu_level_disply.map(item=>
                                        <option value={item.edu_level_id} >{item.edu_level_name}</option>    
                                    )}
                            </select>
                            
                        </div>

                        

                        <div className="col-sm-4 mb-3">

                            <label className="form-label">เบอร์โทรศัพท์มือถือนักเรียน ( กรอกเฉพาะตัวเลข )</label>
                            <input className="form-control form-control-sm" value={telephone_number} onChange={event=>{
                                //ถ้ามีการเปลี่ยนค่าใน textbox >เบอร์โทรศัพท์ จะมีการตรวจสอบว่าเบอร์โทรศัพท์ถูกต้องหรือไม่
                                //event.target.value คือ ค่าใน textbox 
                                if(event.target.value.length > 10){ return; }//ถ้ามากกว่าสิบหลัก จะใส่ค่าเพิ่มหม่ได้
                                if(event.target.value.substr(0,1).toString()!="0"){ return; }//ตัวแรกของเบอร์โทรศัพท์ต้องเป็น 0
                                //check_int คือ function จากไฟล์ lib.js จะเอาตัวอักษรที่ไม่ใช่ตัวเลขออก
                                set_telephone_number(check_int( event.target.value ));

                                }}    />
                            
                        </div>

                        <div className="col-sm-4 mb-3">

                            <label className="form-label">เบอร์โทรศัพท์ ผู้ปกครอง ( กรอกเฉพาะตัวเลข )</label>
                            <input className="form-control form-control-sm" value={telephone_number_parent} onChange={event=>{
                                //เหมือนกับ เบอร์โทรศัพท์มือถือนักเรียน
                                if(event.target.value.length > 10){ return; }
                                if(event.target.value.substr(0,1).toString()!="0"){ return; }

                                set_telephone_number_parent(check_int( event.target.value ));

                            }}    />
                            
                        </div>

                       
                        <div className="col-sm-4 mb-3">

                            <label className="form-label">โรงเรียนที่ศึกษาอยู่</label>
                            <input className="form-control form-control-sm" value={school} onChange={event=>set_school(event.target.value)}    />
                            
                        </div>

                        <div className="col-sm-4 mb-3">

                            <label className="form-label">จังหวัดที่โรงเรียนตั้งอยู่</label>
                            <select className="form-select form-select-sm" value={province_id} onChange={event=>set_province_id(event.target.value)} >
                                <option value="" ></option>
                                {province.map(item=>
                                    <option value={item.province_id}>{item.province_name}</option>    
                                )}
                            </select>

                        </div>

                       

                        <div className="col-sm-8 mb-3">
                            <label className="form-label">ที่อยู่ปัจจุบัน</label>
                            <input className="form-control form-control-sm"  value={address} onChange={event=>set_address(event.target.value)} />
                        </div>

                </div>

                       
                <div className="row">
                    
                        <div className="col-sm-3">
                            {/* picture_type สำหรับกำหนดว่า หัว popup ว่าเป็นรูปประเภทไหน  */}
                            {/* upload_index สำหรับกำหนด element image ตอนหมุนรูป โดย 1 คือ รูปนักเรียน 2 สำเนาบัตรประชาชน 3 สำเนาสูติบัตร*/}
                            <Upload label="อัปโหลดรูปนักเรียน" picture_type="รูปนักเรียน" upload_index="1" refresh={(file_name)=>{ //เมื่อ upload เสร็จแล้ว Component Upload จะส่งชื่อไฟล์ที่เพิ่ง upload มาในตัวแปร file_name 
                                //เมื่อ upload เสร็จแล้ว กำหนด sate applicant_picture เป็นชื่อไฟล์ที่เพิ่ง upload
                                set_applicant_picture(file_name);
                            }} />     
                        </div>
                        <div className="col-sm-3">
                            {(applicant_picture!="")?
                                <>
                                {/* ถ้า state ที่เก็บ ไฟล์รูปมีค่าอยู่ จะเอารูปมาแสดง  */}
                                    <img style={{width:"80px",height:"80px","object-fit": "cover","object-position": "100% 0"}} src={server_url()+applicant_picture+"?t="+(new Date()).getTime()}  className="rounded float-start" />&nbsp;
                                    <div className="text-success" >&nbsp;<i className="fa fa-check"></i>&nbsp;ใส่รูปภาพแล้ว</div>
                                    {/* ----Link ไปดูรูปภาพ--- */}
                                    <div><a className="btn btn-link btn-sm" href={server_url()+applicant_picture+"?t="+(new Date()).getTime()} target="_blank" ><i className="fa fa-search"></i>&nbsp;ดูรูป</a></div>

                                </>
                            :null}
                        </div>
                        <div className="col-sm-6">
                            
                        </div>

                        <div className="col-sm-3">
                            <Upload label="อัปโหลดสำเนาบัตรประชาชน" picture_type="สำเนาบัตรประชาชน" upload_index="2"  refresh={(file_name)=>{
                                set_citizen_picture(file_name);
                            }} />     
                        </div>
                        <div className="col-sm-3">
                            {(citizen_picture!="")?
                                <>
                                    <img style={{width:"80px",height:"80px","object-fit": "cover","object-position": "100% 0"}} src={server_url()+citizen_picture+"?t="+(new Date()).getTime()}  className="rounded float-start mt-2" />&nbsp;
                                    <div className="text-success" >&nbsp;<i className="fa fa-check"></i>&nbsp;ใส่รูปภาพแล้ว</div>
                                    <div><a className="btn btn-link btn-sm" href={server_url()+citizen_picture+"?t="+(new Date()).getTime()} target="_blank" ><i className="fa fa-search"></i>&nbsp;ดูรูป</a></div>

                                </>
                            :null}
                        </div>
                        <div className="col-sm-6">
                            
                        </div>

                        <div className="col-sm-3">
                            <Upload label="อัปโหลดสำเนาสูติบัตร" picture_type="สำเนาสูติบัตร" upload_index="3" refresh={(file_name)=>{
                                set_birth_certificate_picture(file_name);
                            }} />     
                        </div>
                        <div className="col-sm-3">
                            {(birth_certificate_picture!="")?
                                <>
                                    <img style={{width:"80px",height:"80px","object-fit": "cover","object-position": "100% 0"}} src={server_url()+birth_certificate_picture+"?t="+(new Date()).getTime()}  className="rounded float-start mt-2" />&nbsp;
                                    <div className="text-success" >&nbsp;<i className="fa fa-check"></i>&nbsp;ใส่รูปภาพแล้ว</div>
                                    <div><a className="btn btn-link btn-sm" href={server_url()+birth_certificate_picture} target="_blank" ><i className="fa fa-search"></i>&nbsp;ดูรูป</a></div>

                                </>
                                :null}
                        </div>
                        <div className="col-sm-6">
                            
                        </div>



                        </div>

                            


                        <div className="text-center"  >
                            {/*-------แสดงรูป Capcha---------*/}
                            <LoadCanvasTemplateNoReload    />
                            <div className="mb-3" style={{"width":"200px","margin":"auto"}}>
                                {/*------- สำหรับกรอก Capcha ---------*/}
                                <input className="form-control form-control-sm" placeholder="กรอกรหัสที่เห็น" value={cap} onChange={event=>set_cap(eng_only(event.target.value))} />

                            </div>

                            <div className="text-danger" >{err}</div>{/*------ส่วนแสดง Error ถ้ากรอกข้อมูลไม่ครบหรือไม่ถูกต้อง-------*/}

                            <button className="btn btn-sm btn-success" type="button" data-loading-text="กำลังโหลด..." onClick={event=>{ save(event) /*----เมื่อกด ลงทะเบียนจะไปทำงานที่ function save----*/ }} >
                                <i className="fa fa-pencil-alt"></i> ลงทะเบียน
                            </button> 

                        </div>

                        <br />
                        <br />
                        <br />
                    

        </>}

        

    </Template>);
}

export default Register;