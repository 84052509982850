const getAge=(sportObj)=>{

    if( sportObj.age1 !="0" && sportObj.age2 !="0" )
    {
        if(sportObj.age1 == sportObj.age2)
        {
            return `${sportObj.age1} ปี `;
        }
        else
        {
            return `${sportObj.age1} ปี ถึง ${sportObj.age2} ปี `;
        }
    }

    return "";

}

const getEduYear=(sportObj)=>{

    if( sportObj.min_edu_level !=null && sportObj.max_edu_level !=null )
    {
        if(sportObj.min_edu_level.edu_level_id == sportObj.max_edu_level.edu_level_id)
        {
            return `${sportObj.min_edu_level.edu_level_name}`;
        }
        else
        {
            return `${sportObj.min_edu_level.edu_level_name} ถึง ${sportObj.max_edu_level.edu_level_name}  `;
        }
    }

    return "";
    
}

const getSportDisplay=(sportObj)=>{


    let age=getAge(sportObj);
    let edu=getEduYear(sportObj);

    let returnStr=sportObj.sport_name;

    if(sportObj.gender!="")
    {
        returnStr+=` ( ${sportObj.gender} ) `;
    }

    if(age!="")
    {
        returnStr+=` อายุ ${age}  `;
    }

    if(edu!="")
    {
        returnStr+=` ชั้น ${edu}  `;
    }

    return returnStr;

}



export {getAge,getEduYear,getSportDisplay};