import axios from "axios";
import { forwardRef, useEffect, useImperativeHandle, useState ,useRef } from "react";
import { useHistory, useParams } from "react-router";
import { server_url } from "../config";
import { loading_button,reset_button,check_int } from "../lib";

const SportForm=(props)=>{

    const history=useHistory();
    const [err,set_err]=useState("");

    const [sport_name,set_sport_name]=useState("");
    const [age1,set_age1]=useState("");
    const [age2,set_age2]=useState("");
    const [gender,set_gender]=useState("");
    const [min_edu_level_id,set_min_edu_level_id]=useState("");
    const [max_edu_level_id,set_max_edu_level_id]=useState("");

    const save= async (event)=>{

        if(sport_name=="")
        {
            set_err("กรุณากรอกชื่อกีฬา");
            return;
        }

        if( (age1!="" && age2=="") || (age1=="" && age2!="") )
        {
            set_err("กรุณากรอกอายุ");
            return;
        }

        if( (min_edu_level_id!="" && max_edu_level_id=="") || (min_edu_level_id=="" && max_edu_level_id!="") )
        {
            set_err("กรุณากรอกชั้นเรียน");
            return;
        }

        let form_data=new FormData();
        form_data.append("token",localStorage["token"]);
        form_data.append("mode","add");

        form_data.append("sport_name",sport_name);
        form_data.append("age1",age1);
        form_data.append("age2",age2);
        form_data.append("gender",gender);
        form_data.append("min_edu_level_id",min_edu_level_id);
        form_data.append("max_edu_level_id",max_edu_level_id);
         
        set_err("");
        let b=event.currentTarget;
        loading_button(b);

        let response=null;
        let json=null;
        
        try {

            response=await axios.post(server_url()+"api/sport.php",form_data);
            reset_button(b);
            json= response.data;

            if(json.error!=null)
            {
                set_err(response.data.error);
                return;
            }
             
            if( typeof( json ) != "object")
            {
                console.log(json);
                set_err(response.data);
                return;
            }
            
        } catch (error) {

            console.log(response);
            set_err(response.data);
            return;
        }

        set_err(<div className="alert alert-success">บันทึกข้อมูลแล้ว</div>);
        
        setTimeout(()=>{
            history.replace("/admin/sport");
            props.refresh();
        },1500);

    };

    return (<div className="card mb-3">

    <div className="card-body">

        <div className="row">

            <div className="col-sm-6">
                <div className="mb-3">
                    <label className="form-label">ชื่อกีฬา <span className="text-danger">*</span></label>
                    <input className="form-control form-control-sm" 
                        value={sport_name}  
                        onChange={event=>{set_sport_name(event.target.value)}}
                    />
                </div>
            </div>
            <div className="col-sm-2">
                <div className="mb-3">
                    <label className="form-label">เพศ  </label>
                    <select className="form-select form-select-sm" 
                        value={gender}  
                        onChange={event=>{set_gender(event.target.value)}} 
                    >
                        <option value="">ไม่จำกัด</option>
                        <option value="ชาย">ชาย</option>
                        <option value="หญิง">หญิง</option>
                    </select>
                </div>
            </div>

            <div className="col-sm-2">
                <div className="mb-3">
                    <label className="form-label">อายุ (ตั้งแต่) ปี  </label>
                    <input className="form-control form-control-sm"  
                        value={age1}  
                        onChange={event=>{set_age1(check_int(event.target.value))}} 
                    />
                </div>
            </div>

            <div className="col-sm-2">
                <div className="mb-3">
                    <label className="form-label">อายุ (ถึง) ปี  </label>
                    <input className="form-control form-control-sm" 
                         value={age2}  
                         onChange={event=>{set_age2(check_int(event.target.value))}} 
                    />
                </div>
            </div>

            <div className="col-sm-3">
                <div className="mb-3">
                    <label className="form-label">ชั้นเรียน (ตั้งแต่)   </label>
                    <select className="form-select form-select-sm"  
                         value={min_edu_level_id}  
                         onChange={event=>{set_min_edu_level_id(event.target.value)}} 
                    >
                                <option value=""></option>
                                {props.edu_level.map(item=>
                                        <option value={item.edu_level_id} >{item.edu_level_name}</option>    
                                )}
                    </select>
                </div>
            </div>

            <div className="col-sm-3">
                <div className="mb-3">
                    <label className="form-label">ชั้นเรียน (ถึง)   </label>
                    <select className="form-select form-select-sm" 
                         value={max_edu_level_id}  
                         onChange={event=>{set_max_edu_level_id(event.target.value)}} 
                    >
                                <option value=""></option>
                                {props.edu_level.map(item=>
                                        <option value={item.edu_level_id} >{item.edu_level_name}</option>    
                                )}
                    </select>
                </div>
            </div>
            
        </div>

            <div className="mt-3">
                <div className="text-danger mb-3">{err}</div>
                <button className="btn btn-sm btn-success" type="button"
                     data-loading-text="กำลังบันทึก"
                     onClick={event=>{ 
                         save(event);
                     }}
                ><i className="fa fa-check"></i> บันทึก</button>&nbsp;
                <button className="btn btn-sm btn-secondary" type="button"
                    onClick={event=>{ 
                        history.push("/admin/sport"); 
                    }}
                ><i className="fa fa-sign-out-alt"></i> กลับ</button>&nbsp;
            </div>
            

    </div>

</div>)

}

export default SportForm;