import axios from "axios";
import { forwardRef, useEffect, useImperativeHandle, useState ,useRef } from "react";
import { useHistory, useParams } from "react-router";
import { server_url } from "../config";
import { date_thai, loading_button,reset_button } from "../lib";
import { picker_date } from "../picker_date";

const ScheduleForm =(props)=>{

    const history=useHistory();

    const [err,set_err]=useState("");
    const [year_arr,set_year_arr]=useState([]);
    const [loading,set_loading]=useState(null);

    const [topic,set_topic]=useState("");
    const [register_year,set_register_year]=useState("");

    const didMount = useRef(false);
    const {id}=useParams();

    const save= async (event)=>{

        

        if(
            document.getElementById("schedule_date").value=="" ||
            topic=="" ||
            register_year==""  
        )
        {
            set_err("กรุณากรอกข้อมูลให้ครบ");
            return;
        }
        let form_data=new FormData();
        
        if(id==null)
        {
            form_data.append("mode","add");
        }
        else
        {
            form_data.append("mode","edit");
            form_data.append("schedule_id",id);
        }
        
        
        form_data.append("token",localStorage["token"]); 
        form_data.append("register_year",register_year); 
        form_data.append("topic",topic); 
        form_data.append("schedule_date",  document.getElementById("schedule_date").value); 
        //------debug------
        // for (var pair of form_data.entries()) {
        //     console.log(pair[0]+ ', ' + pair[1]); 
        // }
       
        set_err("");
        let b=event.currentTarget;
        loading_button(b);

        let response="";
        let json="";

        try {
            
            response=await axios.post(server_url()+"api/schedule.php",form_data);
           
            reset_button(b);
            json= response.data;

            if(json.error!=null)
            {
                set_err(response.data.error);
                return;
            }
             
            if( typeof( json ) != "object")
            {
                console.log(json);
                set_err(response.data);
                return;
            }

        } catch (error) {

            console.log(response);
            set_err(response.data);
            return;
        }

        set_err(<div className="alert alert-success">บันทึกข้อมูลแล้ว</div>);
        
        setTimeout(()=>{
            history.replace("/admin/schedule");
            props.refresh();
        },1500);

       

    }
 
    useEffect(()=>{

        set_loading(<div className="text-warning mb-2">กำลังโหลด...</div>);

        picker_date(document.getElementById("schedule_date"),{year_range:"-1:+1"});

        let response=null;
       

        let form_data=new FormData();
        form_data.append("token",localStorage["token"]);
        form_data.append("mode","get_min_year");

        (async function(){
            try {

                response=await axios.post(server_url()+"api/schedule.php",form_data);
                set_loading(false);
                set_year_arr(response.data);

                set_loading(true);

                
            } catch (error) {
                console.log(response);
                return;
            }

                if(id!=null)
                {
                    let form_data=new FormData();
                    form_data.append("schedule_id",id);
                    form_data.append("mode","get");
                    form_data.append("token",localStorage["token"]);
                    form_data.append("page_size","1");
                    axios.post(server_url()+"api/schedule.php",form_data).then(res=>{
                        
                        
                        set_loading(false);
                        if(res.data.data_table.length==0)
                        {
                            return;
                        }
                        let data=res.data.data_table[0];
                        set_topic(data.topic);
                        set_register_year(data.register_year);
                        document.getElementById("schedule_date").value= date_thai(data.schedule_date);

                    });
                }
            

        })()
        

    },[,props]);

    // useEffect(() => {

    //     if (didMount.current) { 

    //         set_load_time_effect(load_time_effect+1); 

    //     }else{ 
    //         didMount.current = true;
    //     }
        
    // }, [...]);
     
    return (<>

       
         <div className="card mb-3">
             <div className="card-body">
                    {loading}
                    <div className="row mt-2">
                        <div className="col-sm-4">
                            <div className="mb-3">
                                <label className="form-label">ปีการศึกษา <span className="text-danger">*</span></label>
                                <select className="form-select form-select-sm" value={register_year} onChange={event=>set_register_year(event.target.value)}>
                                    <option value=""></option>
                                    {year_arr.map(item=>
                                        <option value={item}>{parseInt(item)+543}</option>    
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="mb-3">
                                <label className="form-label">เรื่อง <span className="text-danger">*</span></label>
                                <select className="form-select form-select-sm" value={topic} onChange={event=>{set_topic(event.target.value)}} >
                                    <option value=""></option>
                                    {/* <option value="ประกาศรายชื่อผู้มีสิทธิ์สอบคัดเลือก">ประกาศรายชื่อผู้มีสิทธิ์สอบคัดเลือก</option> */}
                                    <option value="ประกาศรายชื่อผู้มีสิทธิ์สอบทักษะกีฬา และสมรรถภาพทางกาย">ประกาศรายชื่อผู้มีสิทธิ์สอบทักษะกีฬา และสมรรถภาพทางกาย</option>
                                    <option value="ประกาศรายชื่อผู้มีสิทธิ์สอบสัมภาษณ์">ประกาศรายชื่อผู้มีสิทธิ์สอบสัมภาษณ์</option>
                                    <option value="ประกาศรายชื่อผู้มีสิทธิ์เข้าศึกษา">ประกาศรายชื่อผู้มีสิทธิ์เข้าศึกษา</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="mb-3">
                                <label className="form-label">วันที่ <span className="text-danger">*</span></label>
                                <input className="form-control form-control-sm" id="schedule_date" />
                            </div>
                        </div>
                        
                    </div>

                    <div className="mt-3">
                        <div className="text-danger mb-3">{err}</div>
                        <button className="btn btn-sm btn-success" type="button"
                             data-loading-text="กำลังบันทึก"
                             onClick={event=>{ 
                                 save(event);
                             }}
                        ><i className="fa fa-check"></i> บันทึก</button>&nbsp;
                        <button className="btn btn-sm btn-secondary" type="button"
                            onClick={event=>{ history.push("/admin/schedule"); /*props.refresh();*/ }}
                        ><i className="fa fa-sign-out-alt"></i> กลับ</button>&nbsp;
                    </div>

             </div>
         </div>

    </>);

}

export default  forwardRef(ScheduleForm);