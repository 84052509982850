import { HashRouter,Switch,Route,useHistory } from "react-router-dom";

//-------component ต่างๆ--------
import Login from "./admin/login";//หน้า admin login
import Home from "./home";//หน้าแรก 
import AdminHome from "./admin/admin_home";//หน้าแรกหลังจากผู้ดูแลระบบ Login
import Register from "./register";//หน้ารับสมัคร
import Registeration from "./admin/registeration";//หน้าผู้ดูแลระบบดูข้อมูลผู้ที่สมัคร
import Schedule from "./admin/schedule";//หน้าผู้ดูแลระบบกำหนดวันที่รับสมัครของแต่ละปีการศึกษา
import DocumentFile from "./admin/document_file";//หน้าสำหรับผู้ดูแลระบบ Upload ไฟล์ excel
import ScheduleAndDocument from "./schedule_and_document";//หน้าสำหรับ Download เอกสาร
import SearchRegisteration from "./search_registeration";//หน้าสำหรับ Download ใบสมัคร
import Announcement from "./announcement";//หน้าประกาศผลการสมัคร
import Excel from "./admin/excel";//หน้าสำหรับผู้ดูแลระบบ Download ข้อมูลผู้สมัคร
import ExportPdf from "./admin/export_pdf";//Download PDF
import ApplyDate from "./admin/apply_date";//กำหนดวันที่เปิดรับสมัคร ปิดรับสมัคร
import PDF from "./admin/pdf";
import Sport from "./admin/sport";

function App() {

 
  
  return (<div>
   
    <HashRouter>{/*----------- กำหนด Path ต่าง ๆ ของหน้าเว็บ ------------- */}
      <Switch>

          <Route exact path="/"  >
            <Home />
          </Route>
          <Route exact path="/register"  >
            <Register />
          </Route>

          <Route exact path="/schedule_and_document"  >
            <ScheduleAndDocument />
          </Route>
          <Route exact path="/announcement"  >
            <Announcement />
          </Route>

          <Route exact path="/search_registeration"  >
            <SearchRegisteration />
          </Route>

          <Route exact path="/admin"  >
             <Login />
          </Route>
          <Route exact path="/admin/home"  >
             <AdminHome />
          </Route>
          <Route exact path="/admin/registeration"  >
            <Registeration />
          </Route>
          
          <Route exact path="/admin/registeration/:form_mode/:id"  >
            <Registeration />
          </Route>

          <Route exact path="/admin/schedule/"  >
            <Schedule />
          </Route>
          <Route exact path="/admin/schedule/:form_mode"  >
            <Schedule />
          </Route>

          <Route exact path="/admin/schedule/:form_mode/:id"  >
            <Schedule />
          </Route>

          <Route exact path="/admin/document_file/"  >
            <DocumentFile />
          </Route>
          <Route exact path="/admin/document_file/:form_mode"  >
            <DocumentFile />
          </Route>
          <Route exact path="/admin/document_file/:form_mode/:id"  >
            <DocumentFile />
          </Route>

          <Route exact path="/admin/excel"  >
            <Excel />
          </Route>

          <Route exact path="/admin/exportpdf/:edu_year/:status_id/:sport_id"  >
            <ExportPdf />
          </Route>

          <Route exact path="/admin/apply_date"  >
            <ApplyDate />
          </Route>
           
          <Route exact path="/admin/pdf"  >
            <PDF />
          </Route>
          

          <Route exact path="/admin/sport"  >
            <Sport />
          </Route>

          <Route exact path="/admin/sport/:form_mode/:id?"  >
            <Sport />
          </Route>

      </Switch>
    </HashRouter>
    
    </div>);
     
}

export default App;
