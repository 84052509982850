import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import {loading_button,reset_button} from './lib';
import { server_url } from './config';//url ของ server ที่ต้องการเก็บไฟล์
import Popup from './popup';//import component popup
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
 

const Upload=(props)=>{ //component สำหรับ upload รูปภาพ

    const [my_file,set_file]=useState(null);//state สำหรับเก็บ object ไฟล์
    const [btn,set_btn]=useState(null);//
    const [file_name,set_file_name]=useState("");
    const notification=useRef();
    
    useEffect(()=>{
        //---------ชุดคำสั่งที่ทำงานเมื่อ state ของไฟล์ที่เลือก เปลี่ยน-----
        if(my_file==null)//ถ้าไม่ได้เลือกไฟล์ให้ return ออก
        {
            return;
        }
        //เก็บ object ไฟล์ที่เลือกไว้ในตัวแปร FormData สำหรับส่งไปบันทึกที่ server
        let formData = new FormData();
        formData.append('my_file', my_file);
        
        if(btn!=null)
        {
            loading_button(btn);//เปลี่ยนสถานะปุ่ม เป็น รอโหลด   
        }
        let form_data=new FormData();
        form_data.append("my_file",my_file);
        form_data.append("mode","upload");
        //-------ส่ง object ไฟล์ไปบันทึกที่ "api/upload.php"
        let obj=[];
        obj['Content-Type']='multipart/form-data';

        axios.post(server_url()+"api/upload.php",form_data,obj).then( (response)=>{

            //-------ชุดคำสั่งเมื่อ upload เสร็จ------

            //------popup สำหรับหมุนรูป-----
            // var myModal = new bootstrap.Modal(document.getElementById("upload_popup"+props.upload_index), {});
            // myModal.show();
            // set_file_name(response.data);
            
            if(props.refresh!=null)
            {//เมื่อ upload เสร็จแล้ว จะส่งชื่อไฟล์ที่เพิ่ง upload ไปที่ หน้าสมัครเพื่อ แสเงรูปที่เพิ่ง upload
                props.refresh(response.data);
            }
            reset_button(btn);//เปลี่ยนสถานะปุ่มเป็นปกติ            
        });

    },[my_file]);

   


    return (<div  className="d-grid gap-2">
    {/*--------- Component สำหรับแจ้งเตือน-----------*/}
    <Popup ref={notification} />
 
            <button className="btn btn-sm btn-primary  mt-2 "  type="button" data-loading-text="กำลังโหลด..." onClick={event=>{
                set_btn(event.currentTarget);//เก็บปุ่มกดเลือกไฟล์ สำหรับ ให้ปุ่มเปลี่ยนเป็นสถานะรอ
                event.currentTarget.nextSibling.click();// เมื่อกดปุ่ม ให้ upload ให้ขึ้น popup ให้เลือกไฟล์
            }}>
                <i className="fa fa-file"></i>&nbsp;{props.label} {/* แสดงปุ่ม upload ตาม property label ที่ส่งมา ( อัปโหลดรูปนักเรียน , อัปโหลดสำเนาบัตรประชาชน , อัปโหลดสำเนาสูติบัตร ) */}
            </button> 
            <input style={{display:"none"}} type="file" onChange={event=>{
                //--------ชุดคำสั่ง ถ้ามีการเลือกไฟล์ แล้วจะให้ทำอะไต่อ------
                if(
                    event.target.value.toLowerCase().indexOf(".jpg")==-1 &&
                    event.target.value.toLowerCase().indexOf(".jpeg")==-1 &&
                    event.target.value.toLowerCase().indexOf(".png")==-1 &&
                    event.target.value.toLowerCase().indexOf(".gif")==-1 
                )
                {//ถ้าไฟล์ที่ upload ไม่ใช่รูปภาพให้ขึ้นแจ้งเตือน
                    notification.current.open("ข้อมูลไม่ถูกต้อง","กรุณาเลือกเฉพาะไฟล์ รูปภาพ ");
                    return;
                }
                //รูปต้องไม่เกิน 1 MB
                var size = parseFloat(event.target.files[0].size / 1024).toFixed(2);
                if(size > 1024)
                {
                    notification.current.open("ข้อมูลไม่ถูกต้อง","ไฟล์รูปภาพห้ามเกิน 1 MB.");
                    return;
                }
                //เก็บ ไฟล์ที่เลือกไว้ใน state
                set_file(event.target.files[0])
            }} accept="image/*" />
            <span className='text-danger'> ไฟล์ upload ต้องมีขนาดไม่เกิน 1 MB</span>
{/*-------popup สำหรับหมุนรูป------ */}
<div className="modal fade" id={"upload_popup"+props.upload_index} tabIndex="-1" aria-labelledby={"upload_popup"+props.upload_index} aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id={"upload_popup"+props.upload_index} >{props.picture_type}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-center">

                <div className="text-center mt-3">

                    <button type="button" className="btn btn-sm btn-warning" data-loading-text="กำลังโหลด..."  
                        onClick={event=>{

                            
                            let b=event.currentTarget;
                            loading_button(b);
                            let form_data=new FormData();
                            form_data.append("mode","rotage");
                            form_data.append("file_name",file_name);
                            axios.post(server_url()+"api/rotage.php",form_data).then(response=>{
                                //console.log(response);
                                reset_button(b);
                                document.getElementById("display_upload_img"+props.upload_index).src=
                                document.getElementById("display_upload_img"+props.upload_index).src+"?t="+(new Date()).getTime();
                            });
                        }}
                    >  <i className="fa fa-undo" ></i> หมุนรูป</button> &nbsp;

                    <button type="button" className="btn btn-sm btn-success"  data-bs-dismiss="modal"
                        onClick={event=>{
                            
                            //document.getElementById('notification_popup').hide();
                            var myModal = new bootstrap.Modal(document.getElementById("upload_popup"+props.upload_index), {})
                            myModal.hide();
                            props.refresh(file_name);
                        }}
                    >  <i className="fa fa-check" ></i> รูปภาพถูกต้อง</button>


                </div>

                <img id={"display_upload_img"+props.upload_index}  className="mt-3" src={server_url()+file_name} style={{width:"100%"}} />

                
                
            </div>
            
            </div>
        </div>
        </div>
                     

    </div>)
};

export default Upload;