import { useEffect,useState } from "react";
import Template from "./template";
import axios from 'axios';
import {server_url} from "../config";
import { date_thai, loading_button, reset_button } from "../lib";

const Excel=()=>{

    const [year,set_year]=useState([]);//ปีการศึกษาที่เปิดรับสมัคร ( สำหรับใส่ในตัวเลือก )
    const [transaction_type,set_transaction_type]=useState([]);//สถานะการสมัคร ( สำหรับใส่ในตัวเลือก )

    const [register_year,set_register_year]=useState("");//ปีการศึกษาที่เลือก
    const [transaction_type_id,set_transaction_type_id]=useState("");//สถานะที่เลือก
    const [data_table,set_data_table]=useState([]);//state สำหรับเก็บ ข้อมูลผู้สมัคาที่ load มาจาก database

    const [export_type,set_export_type]=useState("");
    const [err,set_err]=useState("");//สำหรับ แสดงข้อความ error ( สำหรับ google sheet )

    const [spreadsheetUrl_link,set_spreadsheetUrl_link]=useState("");//ถ้า export google sheet เสร็จ เก็บ link สำหรับ download

    const load_data=(event,type)=>{

        if(register_year=="")// ถ้าข้อมูลไม่ครบให้ขึ้นข้อความแจ้งเตือน
        {
            window.show_notification("ข้อมูลไม่ครบ","กรุณาเลือก ปีการศึกษาที่สมัคร");
            return;
        }
        
        //เปลี่ยนสถานะปุ่มเป็นกำลัง โหลด...
        let b=event.currentTarget;
        loading_button(b);

        //set_data_table([]);


        let form_data=new FormData();
        //ส่ง parameter mode ปีการศึกษา สถานะของผู้สมัครไปที่ api/registeration.php เพื่ออ่านข้อมูลผู้สมัครมาเก็บไว้ใน data_table
        form_data.append("mode","download");//เพื่อให้ "api/registeration.php" เตรียมข้อมูลของผู้สมัครเพื่อ download
        form_data.append("register_year",register_year);
        form_data.append("transaction_type_id",transaction_type_id);
        form_data.append("token",localStorage["token"]);//ส่ง token เพื่อ แสดงว่า login แล้ว

        axios.post(server_url()+"api/registeration.php",form_data).then(response=>{

            //ชุดคำสั่งเมื่อ "api/registeration.php" ส่งข้อมูลผู้สมัครกลับมา

            //console.log(response);

            if(typeof(response.data)!="object")
            {

                document.write(response.data);
                return
            }

            reset_button(b);//เปลี่ยนสถานะปุ่มเป็น ปกติ
            set_data_table(response.data);//เอาข้อมูลผู้สมัครไปใส่ใน state data_table
            set_export_type(type);//set state รูปแบบการ export ( excel หรือ google sheet )
            if(response.data.length==0)//ถ้าไม่มีข้อมูลขึ้น popup แจ้งเตือน
            {
                window.show_notification("ไม่พบข้อมูล","ไม่พบข้อมูล ผู้สมัคร");
                return
            }

            if(type=="Excel")//เรียก fucntion download ถ้า เลือก download เป็น excel
            {   
                downlaod();
            }
            else if(type=="Google_Sheet")//เรียก fucntion export_google_sheet ถ้า เลือก download เป็น google sheet
            {
                export_google_sheet(response.data);
            }

        });

    }

    // useEffect(()=>{

    //     if(data_table.length!=0)
    //     {
            
    //         if(export_type=="Excel")
    //         {   
    //             downlaod();
    //         }
    //         else if(export_type=="Google_Sheet")
    //         {
    //             export_google_sheet();
    //         }
            
    //     }

    // },[data_table]);
    // function  สำหรับ download excel
    const downlaod=()=>{
       
        let sheet_name="ข้อมูลผู้สมัคร";//สร้างชื่อ sheet

        let XLSX=window.XLSX;
        //ให้สร้าง excel จาก table ที่มี id เป็น myTable
        var elt = document.getElementById('myTable');
        var wb = XLSX.utils.table_to_book(elt, {sheet: sheet_name});
        //XLSX.write(wb, {bookType:'xlsx', bookSST:true, type: 'base64'})
        XLSX.writeFile(wb,  ('recruitment.xlsx'));//ให้ browser download
        
    }
    //สำหรับสร้าง google sheet
    const export_google_sheet=(data)=>{

        loading_button(document.getElementById("excel_btn"));//เปลี่ยนปุ่มให้เป็นสถานะกำลังโหลด...
        //key สำหรับ google sheet api
        var CLIENT_ID = '867721234789-cf2d0c6dhi6npaecvmfdajahmhc3fa0j.apps.googleusercontent.com';
        var API_KEY = 'AIzaSyCpg0s5cmMUQ37sLu0210CRvs8_LrQEsiw';
  
        //ใช้ google sheet api version 4 
        var DISCOVERY_DOCS = ["https://sheets.googleapis.com/$discovery/rest?version=v4"];
        //กำหนด title ของ sheet
        var title_name="ข้อมูลผู้สมัครปีการศึกษา "+(parseInt(register_year)+543).toString();//เปลี่ยน ปีการศึกษา คศ เป็น พศ
         
        if(transaction_type_id!="")//ถ้าเลือกสถานะให้เอาชื่อสถานะมาแสดงใน title ด้วย
        {
            if(transaction_type_id=="-1")//ยังไม่มีสถานะ
            {
                title_name+="_ยังไม่มีสถานะ";
            }
            else
            {   //เอาชื่อสถานะมาแสดง
                title_name+="_"+transaction_type[transaction_type.findIndex(item=>{ return item.transaction_type_id==transaction_type_id; })].transaction_type_name;
            }
         
        }
        //เอาวันที่ปัจจุบันมาแสดงใน title ด้วย
        let date_now=new Date();

        title_name+=" วันที่ "+date_now.getDate().toString();
        title_name+=" / "+(date_now.getMonth()+1).toString();
        title_name+=" / "+(date_now.getFullYear()+543).toString();
     
        var SCOPES = "https://www.googleapis.com/auth/spreadsheets";//ระบุว่าจะเข้าถึงเฉพาะ google sheet
        var gapi = window.gapi;//object จาก <script src="https://apis.google.com/js/api.js" > ( อยู่ใน public/index.html   )
        
        gapi.load('client:auth2', ()=>{ //เริ่มต้นเชื่อมต่อ API
            // ส่ง option ที่ต้องการ
            gapi.client.init({
                apiKey: API_KEY,//key ของ google cloud API
                clientId: CLIENT_ID,
                discoveryDocs: DISCOVERY_DOCS,//ใช้ google sheet api version 4 
                scope: SCOPES////ระบุว่าจะเข้าถึงเฉพาะ google sheet
            }).then(function () {

                console.log("gapi.client.init success");
                //-------ขออนุญาตเข้าถึง-------
                gapi.auth2.getAuthInstance().signIn().then(()=>{
                    //---ชุดคำสั่งถ้าอนุญาตเข้าถึงแล้ว----
                    console.log("signIn success");
                    //สร้าง google sheet อันใหม่ 
                    gapi.client.sheets.spreadsheets.create({
                        properties: {
                            title: title_name//โดยมีชื่อของ sheet ตาม title name
                        }
                    }).then((response) => {

                        console.log("create success");
                        //สร้าง sheet เสร็จแล้วจะเอาข้อมูลผู้สมัครไปใส่
                        //console.log(response.result);

                        let spreadsheetId=response.result.spreadsheetId;
                        let spreadsheetUrl=response.result.spreadsheetUrl;

                        //console.log(spreadsheetId);
                        //console.log(spreadsheetUrl);

                            var params = {
                                "spreadsheetId": spreadsheetId,  // TODO: Update placeholder value.
                                "range":"Sheet1",
                                "valueInputOption": 'RAW',
                            };
                    
                            var val=[];//val คือข้อมูลที่จะเอาไปใส่ใน google sheet
                            data.map((row,index)=>{ //ดึงข้อมูลจาก datatable

                                if(index==0)//ถ้าเป็นแถวแรกให้ใส่หัวข้างบนก่อน
                                {
                                    val[index]=[
                                        "เลขประจำตัวสอบ่",
                                        "ชื่อ - นามสกุล่",
                                        "สมัครเข้าเรียนชั้น่",
                                        "วันเกิด",
                                        "อายุ",
                                        "เลขที่ประจำตัวประชาชน",
                                        "ชนิดกีฬา",
                                        "เบอร์โทรศัพท์",
                                        "เบอร์โทรศัพท์ ( ผู้ปกครอง )",
                                        "โรงเรียน",
                                        "จังหวัด",
                                        "Email",
                                        "ที่อยู่",
                                        "Link ใบสมัคร",
                                    ]; 
                                }
                                //ข้อมูลผู้สมัคร แต่ละ column
                                var temp=[];
                                temp.push(row.registeration_code);//เลขที่ผู้สมัคร
                                temp.push(row.title+" "+row.name+" "+row.family_name);
                                temp.push(row.edu_level.edu_level_name);
                                temp.push(date_thai(row.birth_date));
                                temp.push(get_age(row.birth_date));
                                temp.push(row.citizen_id);
                                var age_detail="";
                                //สำหรับข้อมูลกีฬาต้องดูว่ามีข้อมูลอายุกับเพศหรือไม่
                                if(row.sport.gender!=""){age_detail+=row.sport.gender}
                                if(row.sport.age1!="0"!=""){age_detail+=" อายุ "+row.sport.age1+" ถึง "+row.sport.age2+" ปี"}
                              
                                temp.push(row.sport.sport_name+age_detail);
                                temp.push(row.telephone_number);
                                temp.push(row.telephone_number_parent);
                                temp.push(row.school);
                                temp.push(row.province.province_name);
                                temp.push(row.email);
                                temp.push(row.address);
                                //temp.push("https://bangkoksportsschool.com/pdf/index.php?user_key="+row.user_key);
                                temp.push("http://office2.bangkok.go.th/bangkokyouth/bangkoksportsschool/pdf/index.php?user_key="+row.user_key);
                                //link เปิด pdf
                                val[index+1]=temp;


                            });
                            //เมื่อสร้างข้อมูลเสร็จแล้ว ให้ update google sheet
                            gapi.client.sheets.spreadsheets.values.update(params, {values:val}).then((google_sheet_response)=> {

                                // TODO: Change code below to process the `response` object:
                                reset_button(document.getElementById("excel_btn"));//เปลี่ยนปุ่มเป็นสถานะปกติ
                                window.show_notification("ดำเนินการเรียบร้อยแล้ว","สร้าง Google Sheet แล้ว");//ขึ้นข้อความว่าดำเนินการแล้ว
                                set_spreadsheetUrl_link(spreadsheetUrl);//แสดง link ให้ไปเปิดใน google map
                                //window.open(spreadsheetUrl,"_blank");

                            }, function(error) {
                                set_err( JSON.stringify(error));
                            });


                    });


                },(error)=>{ set_err( JSON.stringify(error)); });
                
              

               
            }, function(error) {
                set_err( JSON.stringify(error));
            });

        });
       

      

    }

    
        useEffect(  () => {
            //ชุดคำสั่งที่ทำงานเมื่อ โหลดหน้าเว็บเสร็จ
            (async function(){
                
                let form_data=new FormData();
                form_data.append("mode","get_sport");
                form_data.append("all","1");//all แสดงว่า เอา  disabled  ด้วย

    
                form_data=new FormData();//หาสถานะการสมัครที่มีอยู่ใน database
                form_data.append("mode","get_transaction_type");
                let response=await axios.post(server_url()+"api/registeration.php",form_data);
                response.data=response.data.filter(item=>{ return item.transaction_type_id < 5 });//ไม่เอาสาุนะไม่มีสิทธิ
                set_transaction_type(response.data);//เมื่อหาเสร็จแล้วเก็บไว้ใน state transaction_type
    
                form_data=new FormData();
                form_data.append("mode","get_register_year");//หาปีการศึกษาที่มีปู้สมัคร
                response=await axios.post(server_url()+"api/registeration.php",form_data);
                set_year(response.data);//เมื่อหาเสร็จแล้วเก็บไว้ใน state year
    
    
            })();
            
    
        },[]);
        //สำหรับ หาอายุจากวันเกิด
        const get_age=(DOB)=>
        {
            if(DOB=="")
            {
                return "";
            }


            //----นับ 1 มกราคม------
            let arr=DOB.split("-");
            arr[0]=arr[0];
            arr[1]="01";
            arr[2]="01";
            DOB=arr[0]+"-"+arr[1]+"-"+arr[2];

                var today = new Date();
                var birthDate = new Date(DOB);
                var age = today.getFullYear() - birthDate.getFullYear();
                var m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                    age = age - 1;
                }

                return age;
        }
 
    const export_pdf=()=>{

        if(register_year=="" || transaction_type_id=="")// ถ้าข้อมูลไม่ครบให้ขึ้นข้อความแจ้งเตือน
        {
            window.show_notification("ข้อมูลไม่ครบ","กรุณาเลือก สถานะ และปีการศึกษาที่สมัคร");
            return;
        }

        if(transaction_type_id=="-1")
        {
            window.show_notification("ข้อมูลไม่ถูกต้อง","ไม่สามารถแสดงผู้สมัครที่ยังไม่มีสถานะได้");
            return;
        }

        window.open(`#/admin/exportpdf/${register_year}/${transaction_type_id}`,"_blank");

    }

    return (<Template>

    <div className="mt-2 row">

             
            <div className="col-sm-2">
                <div className="mb-3">
                    <label className="form-label">ปีการศึกษาที่สมัคร</label>
                    {/*   ตัวเลือก ปีการศึกษา */}
                    <select className="form-select form-select-sm" value={register_year} 
                        onChange={event=>set_register_year(event.target.value)} >{/* ถ้าเปลี่ยนตัวเลือก จะเก็บปีการศึกษาที่เลือกไว้ใน state register_year */}
                        
                        <option value=""></option>
                        {year.map(item=>
                            <option value={item.register_year}>{parseInt(item.register_year)+543}</option>    
                        )}

                    </select>
                </div>
            </div>

           
            <div className="col-sm-2">
                <div className="mb-3">
                    {/*  ตัวเลือกสถานะผู้สมัคร */}
                    <label className="form-label">สถานะ</label>
                    <select className="form-select form-select-sm" value={transaction_type_id} 
                        onChange={event=>set_transaction_type_id(event.target.value)} >{/* ถ้าเปลี่ยนตัวเลือก จะเก็บปีการศึกษาที่เลือกไว้ใน state transaction_type_id */}
                        
                        <option value=""></option>
                        <option value="-1">ยังไม่มีสถานะ</option>
                        {transaction_type.map(item=>
                                    <option value={item.transaction_type_id}>
                                        {item.transaction_type_name}
                                    </option>    
                        )}

                    </select>
                </div>
            </div>

            <div className="col-sm-8">
                <div className="mb-3" style={{marginTop:"30px"}}>
                    <label className="form-label"> </label>
                    <button className="btn btn-sm btn-success " type="button" data-loading-text="กำลังโหลด..." 
                        onClick={event=>{load_data(event,"Excel")} /*  ปุ่ม Download ถ้ากด Download จะเรียก function load_data แต่ส่ง type เป็น Excel */ }>
                        <i className="fa fa-download"></i> Download
                    </button>
                    &nbsp;
                    {/* <button className="btn btn-sm btn-success " id="excel_btn" type="button" data-loading-text="กำลังโหลด..." 
                    onClick={event=>{ export_pdf(); } } >
                        <i className="fa fa-download"></i> Export PDF
                    </button>
                    &nbsp; */}
                    <button className="btn btn-sm btn-success " id="excel_btn" type="button" data-loading-text="กำลังโหลด..." 
                    onClick={event=>{load_data(event,"Google_Sheet")} 
                    /*  ปุ่ม Export Google Sheet ถ้ากด Export Google Sheet จะเรียก function load_data แต่ ส่ง type เป็น Google_Sheet */  }>
                        <i className="fa fa-download"></i> Export Google Sheet
                    </button>
                    &nbsp;
                    {/* ถ้าสร้าง google sheet เสร็จ จะแสดง link สำหรับ เปิด google sheet */}
                    {(spreadsheetUrl_link!="")?
                        <a className="btn btn-link btn-sm" href={spreadsheetUrl_link} target="_blank">
                            <i className="fa fa-external-link-square-alt"></i> เปิด Google Sheet
                        </a>
                    :null}
                </div>
            </div>

             
        </div>
        {/*  ส่วนแสดงข้อความถ้าสร้าง google sheet error */}
       <div className="mt-3 text-danger">{err}</div>
        {/* ตารางข้อมูลผู้สมัคร ไม่ได้แสดง แต่เอาไว้สร้าง excel  */}                    
        <table  id="myTable" style={{display:"none"}}>
            {/* กำหนด  id="myTable" เพราะใน function  download จะมี var elt = document.getElementById('myTable'); เพื่อ เอาข้อมูลใน table id myTable ไปสร้างเป็น excel   */}
                <tr >
                        <td>เลขประจำตัวสอบ</td>
                        <td>ชื่อ - นามสกุล</td>
                        <td>สมัครเข้าเรียนชั้น</td>
                        <td>วันเกิด</td>
                        <td>อายุ</td>
                        <td>เลขที่ประจำตัวประชาชน</td>
                        <td>ชนิดกีฬา</td>
                        <td>เบอร์โทรศัพท์</td>
                        <td>เบอร์โทรศัพท์ ( ผู้ปกครอง )</td>
                        <td>โรงเรียน</td>
                        <td>จังหวัด</td>
                        <td>Email</td>
                        <td>ที่อยู่</td>
                </tr>
                {data_table.map(row=>
                    <tr>
                        <td>{row.registeration_code}</td>
                        <td>{row.title} {row.name} {row.family_name}</td>
                        <td>{row.edu_level.edu_level_name} </td>
                        <td>{date_thai(row.birth_date)} </td>
                        <td>{get_age(row.birth_date)} ปี</td>
                        <td>{(row.citizen_id)}</td>
                        <td>{row.sport.sport_name} {row.sport.gender!=""?row.sport.gender:null} {row.sport.age1!="0"?" อายุ "+row.sport.age1+" ถึง "+row.sport.age2+" ปี":null}</td>
                        <td>{row.telephone_number}</td>
                        <td>{row.telephone_number_parent}</td>
                        <td>{row.school}</td>
                        <td>{row.province.province_name}</td>
                        <td>{row.email}</td>
                        <td>{row.address}</td>
                    </tr>    
                )}
        </table>


    </Template>);
}

export default Excel