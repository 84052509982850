export var loading_button=(b)=>{

    if(b==null)
    {
        return;
    }
    b.setAttribute("temp_text",b.innerHTML);
    let loading_text="กำลังโหลด...";
    if(b.getAttribute("data-loading-text")!=null)
    {
        loading_text=b.getAttribute("data-loading-text");
    }
   
    b.innerHTML='<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> '+loading_text;
    b.classList.add("disabled");
    b.disabled=true;

}

export var reset_button=(b)=>{

    if(b==null)
    {
        return;
    }
     
    b.classList.remove("disabled");
    b.innerHTML= b.getAttribute("temp_text");
    b.disabled=false;


}

export var date_thai=(date)=>{

        if(date==null)
        {
            return;
        }

        var time="";
		
		if (date.indexOf(" ")!=-1)
		{
            var arr=date.split(" ");
		    time=arr[1];
        }
		
		var date_arr=[];
		if (date.indexOf(" ")!=-1)
		{
		   date_arr=arr[0].split("-");
		}
		else
		{
		   date_arr=date.split("-");
		}
        return date_arr[2]+"/"+date_arr[1]+"/"+(parseInt(date_arr[0])+543)+" "+time;

};


export var date_thai_name=(date)=>{

    if(date==null)
    {
        return;
    }

    var time="";
    
    if (date.indexOf(" ")!=-1)
    {
        var arr=date.split(" ");
        time=arr[1];
    }
    
    var date_arr=[];
    if (date.indexOf(" ")!=-1)
    {
       date_arr=arr[0].split("-");
    }
    else
    {
       date_arr=date.split("-");
    }

    if(date_arr[1]=="01"){date_arr[1]="มกราคม";}
    if(date_arr[1]=="02"){date_arr[1]="กุมภาพันธ์";}
    if(date_arr[1]=="03"){date_arr[1]="มีนาคม";}
    if(date_arr[1]=="04"){date_arr[1]="เมษายน";}
    if(date_arr[1]=="05"){date_arr[1]="พฤษภาคม";}
    if(date_arr[1]=="06"){date_arr[1]="มิถุนายน";}
    if(date_arr[1]=="07"){date_arr[1]="กรกฎาคม";}
    if(date_arr[1]=="08"){date_arr[1]="สิงหาคม";}
    if(date_arr[1]=="09"){date_arr[1]="กันยายน";}
    if(date_arr[1]=="10"){date_arr[1]="ตุลาคม";}
    if(date_arr[1]=="11"){date_arr[1]="พฤศจิกายน";}
    if(date_arr[1]=="12"){date_arr[1]="ธันวาคม";}

    return date_arr[2]+" "+date_arr[1]+" "+(parseInt(date_arr[0])+543)+" "+time;

};



export var eng_only=(str)=>{

    if(str==null)
    {
        return;
    }
    
    var temp=str; 
 
    temp=temp.toLowerCase(); 
    
     for(var i=0;i<temp.length;i++)
    {
    
        if ((temp[i]=="a")||(temp[i]=="b")||(temp[i]=="c")||(temp[i]=="d")||(temp[i]=="e")||(temp[i]=="f")||(temp[i]=="g")||(temp[i]=="h")||(temp[i]=="i")||(temp[i]=="j")||(temp[i]=="k")||(temp[i]=="l")||(temp[i]=="m")||(temp[i]=="n")||(temp[i]=="o")||(temp[i]=="p")||(temp[i]=="q")||(temp[i]=="r")||(temp[i]=="s")||(temp[i]=="t")||(temp[i]=="u")||(temp[i]=="v")||(temp[i]=="w")||(temp[i]=="x")||(temp[i]=="y")||(temp[i]=="z")||(temp[i]=="z")||(temp[i]=="0")||(temp[i]=="1")||(temp[i]=="2")||(temp[i]=="3")||(temp[i]=="4")||(temp[i]=="5")||(temp[i]=="6")||(temp[i]=="7")||(temp[i]=="8")||(temp[i]=="9")||(temp[i]=="!")||(temp[i]=="@")||(temp[i]=="#")||(temp[i]=="$")||(temp[i]=="%")||
        (temp[i]=="^")||(temp[i]=="&")||(temp[i]=="*")||(temp[i]=="(")||(temp[i]==")")||(temp[i]=="_")||(temp[i]=="+")||(temp[i]=="-")||(temp[i]=="=")||(temp[i]=="{")||(temp[i]=="}")||(temp[i]=="[")||(temp[i]=="]")||(temp[i]=="|")||(temp[i]=="/")||(temp[i]=="\"")||(temp[i]=="'")||(temp[i]==":")||(temp[i]==";")||(temp[i]=="<")||(temp[i]==">")
        ||(temp[i]=="."))
        {
            
        }
        else
        {
            str=str.replace(temp[i],"");
            
        }

    }

    return str;

}

export var check_int=(str)=>{

    if(str==null)
    {
        return;
    }

    var ch = str;  //เก็บข้อความใน textbox ไว้ในตัวแปร ch
    var digit;  //ตัวแปรสำหรับเก็บตัวอักษรแต่ละอักขระในตัวแปร ch

        // วน loop หาแต่ละตัวอักขระใน text box
        for (var i = 0 ; i < ch.length ; i++) {
            digit = ch.charAt(i)

            if (digit >= "0" && digit <= "9") //แต่ละอักขระอยู่ในช่วง 0-9  และเป็นทศนิยม หรือไม่
            {

            } else
            {
                ch=ch.replace(digit, "");
                //ถ้าไม่ได้อยู่ในช่วง 0-9 และไม่ได้เป็นทศนิยมจะตัดตัวอักขระตัวนั้นออก
            }

        }
        
        return ch;

}