import { useEffect, useRef,useState } from "react";
import { useHistory,useParams } from "react-router";
import Template from "./template";
import axios from 'axios';
import {server_url} from "../config";
import { loading_button, reset_button } from "../lib";
import {getAge,getEduYear,getSportDisplay} from "../sport_display_text";
import SportForm from "./sport_form";


const Sport=()=>{

    const [loading, set_loading] = useState(false);
    const [data_table, set_data_table] = useState([]);
    const [edu_level, set_edu_level] = useState([]);

    const history=useHistory();
    const {form_mode}  = useParams();
    const form_ref=useRef();

    const open_form=()=>{

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
       
        history.push('/admin/sport/form/');
       
    }

    const load_page=async ()=>{

        set_loading(true);
        set_data_table([]);

        let json=null;
        let form_data=new FormData();
        form_data.append("token",localStorage["token"]);
        form_data.append("all","1");
        form_data.append("mode","get_sport");

        let response;
        try
        {
            response =await axios.post(server_url()+"api/register.php",form_data);
            json=response.data;

            if(typeof (json)!="object")
            {
                console.log(json);
                return;
            }
        }
        catch(ex)
        {
            console.log(ex);
            console.log(response);
            return;
        }

        json=response.data;
        set_data_table(json);
        set_loading(false);
    }

    useEffect(()=>{
        
        (async function(){

            await load_page();

            let formData= new FormData();
            formData.append("mode","get_edu_level");

            let response=await axios.post(server_url()+"api/register.php",formData);
            set_edu_level(response.data);

        })()
        
    },[]);

    const change_status=(event,row)=>{

        let b=event.currentTarget;
        window.show_confirm("ต้องการเปลี่ยนสถานะ "+getSportDisplay(row)+" ?",()=>{

            loading_button(b);

            let form_data=new FormData();
            form_data.append("mode","change_status");
            form_data.append("sport_id",row.sport_id);
            form_data.append("disable",row.disable=="0"?"1":"0");
            form_data.append("token",localStorage["token"]);

            axios.post(server_url()+"api/sport.php",form_data).then(response=>{

                reset_button(b);

                if(typeof(response.data)!="object")
                {
                    console.log(response.data);
                    return;
                }
                        
                if(response.data.error!=null)
                {
                    window.show_notification("ไม่สามารถเปลี่ยนสถานะได้",response.data.error)
                    return;
                }
             
                load_page();
            });

        });
    }

    return (<Template>

       <h3>กีฬาที่รับสมัคร</h3>
       <hr />
    
            {form_mode=="form"?    

                <SportForm ref={form_ref} refresh={load_page} edu_level={edu_level}  />
                
            :null}

            {form_mode!="form"?<button className="btn btn-sm btn-success" type="button" onClick={event=>open_form(null)} >
                        <i className="fa fa-plus"></i> เพิ่มกีฬา
            </button>:null}
       

       <ul className="list-group mt-3">


            {(loading)?
                <li className="list-group-item list-group-item-warning" >
                    กำลังโหลด.. 
                </li>
            :null}

            {data_table.map(row=>
                <li className="list-group-item " >

                    <div className="row">
                            <div className="col-sm-4">

                                <div> {row.sport_name} 
                                      {(row.disable=="0")? <span className="text-success"> ( เปิดรับสมัคร )</span> : <span className="text-danger"> ( ปิดรับสมัคร )</span> } 
                                </div>
                                    
                            </div>
                            <div className="col-sm-5">

                                
                                {(row.gender!="")?<div><b className="text-primary">เพศ </b> : {row.gender}</div>:null}
                                {(getAge(row)!="")?<div><b className="text-primary">อายุ </b> : {getAge(row)}</div>:null}
                                {(getEduYear(row)!="")?<div><b className="text-primary">ชั้นเรียน </b> : {getEduYear(row)}</div>:null}

                            
                            </div>
                            <div className="col-sm-2">

                                    <button className="btn btn-primary btn-sm" type="button" data-loading-text="กำลังโหลด..."
                                            onClick={event=>{
                                                change_status(event,row);
                                            }}
                                        >
                                            <i className="fa fa-pencil-alt" ></i> เปลี่ยนสถานะ
                                        </button>
                                        &nbsp;
                                        {(row.regis_count =='0')?<button className="btn btn-danger btn-sm" type="button" data-loading-text="กำลังลบ..."
                                            onClick={event=>{

                                                        let b=event.currentTarget;
                                                        window.show_confirm("ต้องการลบ "+getSportDisplay(row)+" ?",()=>{

                                                            loading_button(b);

                                                            let form_data=new FormData();
                                                            form_data.append("mode","del");
                                                            form_data.append("sport_id",row.sport_id);
                                                            form_data.append("token",localStorage["token"]);

                                                            axios.post(server_url()+"api/sport.php",form_data).then(response=>{

                                                                reset_button(b);

                                                                if(typeof(response.data)!="object")
                                                                {
                                                                    console.log(response.data);
                                                                    return;
                                                                }

                                                                if(response.data.error!=null)
                                                                {
                                                                    window.show_notification("ไม่สามารถลบได้",response.data.error)
                                                                    return;
                                                                }
                                                                
                                                                load_page();

                                                            });

                                                        });
                                            }}
                                        >
                                            <i className="fa fa-trash"></i>  ลบ
                                        </button>:null}

                            </div>
                    </div>
                 
                </li>
            )}

        </ul>


    </Template>);
};

export default Sport;
