import { useEffect, useState ,useImperativeHandle,forwardRef, useRef } from 'react';
import Template from './template';
import axios from 'axios';
import {server_url} from "./config";

const Announcement=()=>{

    const [loading,set_loading]=useState(true);//state สำหรับเก็บสถานะกำลังโหลดอยู่
    const [active_tab,set_active_tab]=useState(-1);//state สำหรับ เก็บ tab ที่กำลัง active อยู่

    //active_tab=0 มีสิทธิ์เข้าศึกษา active_tab=1 มีสิทธิ์สอบสัมภาษณ์ active_tab=2 มีสิทธิ์สอบทักษะกีฬา และสมรรถภาพทางกาย
    //สถานะ 1 มีสิทธิ์สอบ สถานะ 2  มีสิทธิ์สอบสัมภาษณ์ สถานะ 3 มีสิทธิ์เข้าศึกษา
    const [registeration0,set_registeration0]=useState([]);//state เก็บข้อมูลผู้ยังไม่มีสถานะ
    const [registeration1,set_registeration1]=useState([]);//state เก็บข้อมูลผู้มีสิทธิ์สอบ 
    const [registeration2,set_registeration2]=useState([]);//state เก็บข้อมูลมีสิทธิ์สอบสัมภาษณ์
    const [registeration3,set_registeration3]=useState([]);//state เก็บข้อมูลมีสิทธิ์เข้าศึกษา
   
    useEffect(()=>{
        // function ที่ ทำงานเมื่อโหลดมาครั้งแรก
        let form_data=new FormData();
        form_data.append("mode","get");
        //เรียก api/announcement.php เพื่อนำข้อมูลผู้สมัครแต่ละสถานะมาแสดง

        axios.post(server_url()+"api/announcement.php",form_data).then(response=>{ //response จาก announcement.php

            set_loading(false);//ปิดสถานะ โหลดเมื่อ "api/announcement.php" ทำงานเสร็จ
            //เอาข้อมูลผู้สมัครแต่ละสถานะเอาไว้ใน state
            set_registeration0(response.data.registeration0);
            set_registeration1(response.data.registeration1);
            set_registeration2(response.data.registeration2);
            set_registeration3(response.data.registeration3);


        });


    },[]);

    useEffect(()=>{

        //set_active_tab(-1);
        //function ที่ทำงาน เมื่อมีการเปลี่ยน state ข้อมูลผู้สมัคร
        if(registeration3.length!=0)//ถ้ามีข้อมูลผู้มีสิทธิ์เข้าศึกษาแสดง tab 0  
        {
            
            set_active_tab(0);
        }
        else if(registeration2.length!=0)//ถ้ามีข้อมูลผู้มีสิทธิ์สอบสัมภาษณ์แสดง tab 1 
        {
            
            set_active_tab(1);
        }
        else if(registeration1.length!=0)//ถ้ามีข้อมูลมีสิทธิ์สอบกีฬาแสดง tab 2
        {
             
            set_active_tab(2);
        }
        else if(registeration0.length!=0)
        {
             
            set_active_tab(-1);
        }


    },[registeration1,registeration2,registeration3]);
 

    return (<Template>

        {/*-----แสดงสถานะกำลังโหลด-----*/}
        {loading?
            <div className="alert alert-warning">กำลังโหลด...</div>
        :null}

        <ul className="nav nav-tabs">
            {(registeration0.length!=0)?<li  className="nav-item" onClick={event=>{
                    set_active_tab(-1);//เมื่อคลิกแล้ว กำหนด tab ที่ active
                }}>
                    <a  className={`nav-link  ${(active_tab==-1)?"active":""}`}    style={{cursor:"pointer"}} >ผู้สมัครเรียนทั้งหมด</a>
                </li>:null}
            {(registeration1.length!=0)?/*------ถ้ามีข้อมูล มีสิทธิ์สอบ ให้มี tab มีสิทธิ์สอบทักษะกีฬา และสมรรถภาพทางกาย-------*/
                <li  className="nav-item" onClick={event=>{
                    set_active_tab(2);//เมื่อคลิกแล้ว กำหนด tab ที่ active
                }}>
                    <a  className={`nav-link  ${(active_tab==2)?"active":""}`}    style={{cursor:"pointer"}} >มีสิทธิ์สอบทักษะกีฬา และสมรรถภาพทางกาย</a>
                </li>
            :null}{/*  {`nav-link  ${(active_tab==2)?"active":""}`} คือใส่ class active ถ้า active_tab เป็น 2  */}
            
            {(registeration2.length!=0)?/*------ถ้ามีข้อมูล ผู้มีสิทธิ์สอบสัมภาษณ์ ให้มี tab มีสิทธิ์สอบสัมภาษณ์-------*/
                <li  className="nav-item"   onClick={event=>{
                     
                    set_active_tab(1);//เมื่อคลิกแล้ว กำหนด tab ที่ active
                }}  >
                    <a className={`nav-link  ${(active_tab==1)?"active":""}`}   style={{cursor:"pointer"}}  >มีสิทธิ์สอบสัมภาษณ์</a>
                </li>
            :null}{/*  {`nav-link  ${(active_tab==1)?"active":""}`} คือใส่ class active ถ้า active_tab เป็น 1 */}
           
            {(registeration3.length!=0)? /*------ถ้ามีข้อมูล ผู้มีสิทธิ์เข้าศึกษา ให้มี tab มีสิทธิ์เข้าศึกษา-------*/
                <li className="nav-item"   onClick={event=>{
                    set_active_tab(0); //เมื่อคลิกแล้ว กำหนด tab ที่ active
                }} >
                    <a className={`nav-link  ${(active_tab==0)?"active":""}`}  style={{cursor:"pointer"}} >มีสิทธิ์เข้าศึกษา</a>{/* style={{cursor:"pointer"}} ถ้าเอา mouse ไป over ให้เป็นรูปมือ */}
                </li>
            :null}{/*  {`nav-link  ${(active_tab==0)?"active":""}`} คือใส่ class active ถ้า active_tab เป็น 0  */}
            
            </ul>
 
            
      
        {/* กรณีไม่มีข้อมูลผู้สมัครเลย */}    
        {(
            loading==false && 
            registeration3.length==0 && 
            registeration2.length==0 && 
            registeration1.length==0 &&
            registeration0.length==0 
        )?
        
            <div className="alert alert-danger">ยังไม่มีข้อมูล</div>

        :null}

        {(active_tab==-1)?
            <table className="table table-sm table-bordered mt-3">
                <thead>
                    <tr className="table-primary">
                        {/* <td>เลขที่ประจำตัวสอบ</td> */}
                        <td>ชื่อ </td>
                        <td>ชนิดกีฬา</td>
                        <td>ระดับชั้น</td>
                    </tr>
                </thead>
                <tbody>
                {registeration0.map(row=>
                    <tr>
                        {/* <td>{row.registeration_code}</td> */}
                        <td>{row.title} {row.name} </td>
                        <td>{row.sport_name}</td>
                        <td>{row.edu_level_name}</td>
                    </tr>    
                )}
                </tbody>
          </table>
        :null}

        {/*  แสดงข้อมูลผู้มีสิทธิ์เข้าศึกษา  */}
        {(active_tab==0)?
            <table className="table table-sm table-bordered mt-3">
                <thead>
                    <tr className="table-primary">
                        <td>เลขที่ประจำตัวสอบ</td>
                        <td>ชื่อ </td>
                        <td>ชนิดกีฬา</td>
                        <td>ระดับชั้น</td>
                    </tr>
                </thead>
                <tbody>
                {registeration3.map(row=>
                    <tr>
                        <td>{row.registeration_code}</td>
                        <td>{row.title} {row.name}  </td>
                        <td>{row.sport_name}</td>
                        <td>{row.edu_level_name}</td>
                    </tr>    
                )}
                </tbody>
          </table>
        :null}
        {/*  แสดงข้อมูลผู้มีสิทธิ์สอบสัมภาษณ์  */}
        {(active_tab==1)?
            <table className="table table-sm table-bordered mt-3">
                <thead>
                    <tr className="table-primary">
                        <td>เลขที่ประจำตัวสอบ</td>
                        <td>ชื่อ  </td>
                        <td>ชนิดกีฬา</td>
                        <td>ระดับชั้น</td>
                    </tr>
                </thead>
                <tbody>
                {registeration2.map(row=>
                    <tr>
                        <td>{row.registeration_code}</td>
                        <td>{row.title} {row.name}</td>
                        <td>{row.sport_name}</td>
                        <td>{row.edu_level_name}</td>
                    </tr>    
                )}
                </tbody>
          </table>
        :null}
        {/*  แสดงข้อมูลผู้มีสิทธิ์ทดสอบททักษะกีฬา  */}
        {(active_tab==2)?
            <table className="table table-sm table-bordered mt-3">
                <thead>
                    <tr className="table-primary">
                        <td>เลขที่ประจำตัวสอบ</td>
                        <td>ชื่อ  </td>
                        <td>ชนิดกีฬา</td>
                        <td>ระดับชั้น</td>
                    </tr>
                </thead>
                <tbody>
                {registeration1.map(row=>
                    <tr>
                        <td>{row.registeration_code}</td>
                        <td>{row.title} {row.name} </td>
                        <td>{row.sport_name}</td>
                        <td>{row.edu_level_name}</td>
                    </tr>    
                )}
                </tbody>
          </table>
        :null}








    </Template>);
}

export default Announcement;