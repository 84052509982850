import axios from "axios";//axios สำหรับเรียกใช้ ajax กับ server
import { server_url } from "./config";//url ของ server ที่จะเชื่อมกับ database
//function  สำหรับดูว่า ตอนนี้อยู่ในช่วงที่รับสมัคร หรือไม่
const check_apply_date=()=>{

    return new Promise((resolve, reject)=>{

        let form_data=new FormData();
        //ส่งตัวแปร mode มีค่าเป็น check_apply_date ให้ไฟล์ apply_date.php 
        //เพื่อระบุว่าต้องการหาว่าช่วงเวลาปัจจุบันสามารถสมัคร ได้หรือไม่  
        form_data.append("mode","check_apply_date");

        axios.post(server_url()+"api/apply_date.php",form_data).then(response=>{

            //return ค่าว่าตอนนี้ สามารถ สมัครได้หรือไม่  ถ้า can_apply เป็น 1 สมัครได้ เป็น 0 สมัครไม่ได้
            resolve(response.data.can_apply);
           

        });

    })

}

export {check_apply_date};