import axios from "axios";
import { useEffect, useState } from "react";
import { server_url } from "./config";
import Template from "./template";
import { date_thai,date_thai_name } from "./lib";

 

const ScheduleAndDocument=()=>{

    const [loading,set_loading]=useState(true);//state สำหรับแสดงสถานะกำลังโหลด
    const [schedule,set_schedule]=useState([]);//state สำหรับเก็บข้อมูลกำหนดการณ์
    const [document_file,set_document_file]=useState([]);//state สำหรับเก็บ document ที่สำหรับ Download


    useEffect(()=>{
        //function ทำงานเมื่อหน้าเว็บโหลดมาครั้งแรก
        (async function(){

            

                try {
                    
                    let form_data=new FormData();
                    form_data.append("mode","get_document_file");//กำหนด mode เป็น get_document_file สำหรับอ่านข้อมูลเอกสารสำหรับ Download จากฐานข้อมูล
                    //เรียก api/schedule_and_document.php เพื่อ อ่านข้อมูล document จากฐานข้อมูล และเก็บข้อมูลเอกสารไว้ในตัวแปร response
                    let response=await axios.post(server_url()+"api/schedule_and_document.php",form_data);
                    if(typeof(response.data)!="object")//ถ้า มี error ให้ดู console log
                    {
                        console.log(response.data);
                        return;
                    }

                    set_document_file(response.data);//set state document_file เพื่อแสดงเอกสารสำหรับ Download

                    form_data.append("mode","get_schedule");///กำหนด mode เป็น get_schedule เพื่อ อ่านข้อมูล schedule จากฐานข้อมูล
                    //เรียก "api/schedule_and_document.php" เพื่ออ่านข้อมูล กำหนดการณ์ และเก็บข้อมูล กำหนดการณ์ ไว้ในตัวแปร response
                    response=await axios.post(server_url()+"api/schedule_and_document.php",form_data);
                    if(typeof(response.data)!="object")//ถ้า มี error ให้ดู console log
                    {
                        console.log(response.data);
                        return;
                    }

                    set_schedule(response.data);//set state กำหนดการณ์ 

                    set_loading(false);//ปิดสถานะ Download

                } catch (ex) 
                {
                    console.log(ex);//ถ้าระหว่าง Load ข้อมูลมี Error เอา console.log ออกมาดู
                    return;    
                }

        })()

    },[]);


    return (<Template>

        <h4>กำหนดการและเอกสาร</h4>
        <hr />
        {/* ถ้า state loading เป็น true ให้แสดงข้อความ กำลังโหลด   */}
        {(loading)?
            <div className="alert alert-warning">กำลังโหลด...</div>
        :null}

        <ul className="list-group mt-3">
            <li className="list-group-item active">กำหนดการ</li>{/*----แสดงหัวกำหนด กำหนดการ---*/}
            {(loading==false && schedule.length==0)?
                <li className="list-group-item text-danger">ยังไม่มีข้อมูล</li>
            :null} {/*---- ถ้าโหลดเสร็จแล้ว state schedule ไม่มีข้อมูลให้แสดงข้อความ ยังไม่มีข้อมูล  ---*/}
            {schedule.map(item=> 
                <li className="list-group-item" >{/* ---- แสดงวันที่ และหวัข้อของกำหนดการ ---- */}
                    <b >วันที่ {date_thai_name(item.schedule_date)} :</b> {item.topic}
                </li>    
            )}{/* date_thai_name อยู่ใน lib เอาไว้แปลงจาก ปีเดือนวัน เป็น วัน เดือน ( ชื่อ ) ปี  */}
        </ul>


        <ul className="list-group mt-3">
            <li className="list-group-item active">เอกสาร</li>{/* แสดงหัวข้อเอกสาร  */}
            {(loading==false && document_file.length==0)?
                <li className="list-group-item text-danger">ยังไม่มีข้อมูล</li>
            :null}{/*  ถ้า download เสร็จแล้ว state document_file เป็นค่าว่างให้ แสดงข้อความ ยังไม่มีข้อมูล   */}
            {document_file.map(item=>
                <li className="list-group-item" >
                    <a target="_blank" href={item.document_file_link}> <i className="fa fa-external-link-alt" ></i>&nbsp;{item.document_file_name} </a>
                </li>    
            )}{/* วน loop แสดงชื่อไฟล์ และ link download href={item.document_file_link} เพื่อ download ไฟล์  target="_blank" เพื่อขึ้น tab ใหม่ */}
        </ul>


    </Template>);

}

export default ScheduleAndDocument;
