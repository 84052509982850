import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import '@fortawesome/fontawesome-free/css/all.css';
import { useState } from "react";
import { server_url} from "../config";
import {loading_button, reset_button} from '../lib';
import { useHistory } from "react-router";//สำหรับเปลี่ยนหน้า


const Login=()=>{

    

    const [username,set_username]=useState("");//state สำหรับ username
    const [password,set_password]=useState("");//state สำหรับ password
    const [err,set_err]=useState(null);//state สำหรับ แสดงข้อความ error ถ้า username password ไม่ถูกต้อง

    const history = useHistory();

    const check_login=async ()=>{
        //เราตั้งชื่อปุ่มว่า login_btn
        let b=document.getElementById("login_btn");
        loading_button(b);//เปลี่ยนสถานะปุ่มเป็น กำลังโหลด....
        //ส่ง username password ไปตรวจสอบ 
        let formData=new FormData();
        formData.append("mode","get_token");//ส่ง mode get_token เพื่อให้ตรวจสอบ username password
        formData.append("username",username);
        formData.append("password",password);

        set_err("");// clear ข้อความ error

        let response=await axios.post(server_url()+"api/authentication.php",formData);//ส้งข้อมมูล username password ไปที่ api/authentication.php
        
        reset_button(b);//เปลี่ยนสถานะปุ่มเป็นปกติ
        //ถ้ามี error ให้แสดงข้อความ error
        let json=null;
        try
        {
            json=response.data;
            
            if(json.error!=null)
            {
                set_err(<div className="alert alert-danger mt-2">{json.error}</div>);
                return;
            }

        }catch(e)
        {

            set_err(<div className="alert alert-danger mt-2">{response.data}</div>);
            return;
        }
        //ถ้า username password ถูก
        localStorage["token"]=json.token;//เก็บ token ไว้ใน local storage ( หน้าอื่นๆ สามารถเข้าถึงตัวแปรนี้ได้ )
        history.push("/admin/registeration");//ไปที่หน้าข้อมูลผู้สมัคร
        

    }

    return (<div>


        <div style={{maxWidth:"500px",margin:"auto"}} className="card mt-5">
            <div className="card-header text-center">เข้าสู่ระบบ</div>
            <div className="card-body">

                <div className="mb-3">{/* textbox สำหรับ username */}
                        <label className="form-label">Username</label>
                        {/* ผู้ textbox ไว้กับ state username */}
                        <input className="form-control" 
                            value={username} 
                            onChange={event=>set_username(event.target.value)} />
                </div>

                <div className="mb-3">{/* textbox สำหรับ password */}
                        <label className="form-label">Password</label>
                        {/* ผู้ textbox ไว้กับ state password */}
                        <input className="form-control" type="password" value={password} 
                            onKeyPress={event=>{if(event.key=="Enter"){ check_login(); }}}
                            onChange={event=>set_password(event.target.value)} />
                </div>
                {/* onKeyPress={event=>{if(event.key=="Enter"){ check_login(); }}} ถ้ากด enter จะทำงานที่ function check login */}
                <button className="btn btn-sm btn-primary" id="login_btn" type="button" data-loading-text="กำลังโหลด..." onClick={event=>{check_login()}} >
                    <i className="fa fa-lock"></i> เข้าสู่ระบบ
                </button>
                {/*ถ้ากดปุ่มเข้าสู่ระบบก็จะทำงานที่ function check login เหมือนกัน */}

                {err} {/*  เอาไว้แสเงข้อความ error */}

            </div>
        </div>



    </div>);

}

export default Login;