import { useEffect, useState ,useImperativeHandle,forwardRef, useRef, useLayoutEffect } from 'react';
import Template from './template';
import axios from 'axios';
import {server_url} from "../config";
import { date_thai, loading_button, reset_button } from '../lib';
import { useHistory, useParams } from 'react-router-dom';
import RegisterationForm from './registeration_form';
import {getSportDisplay} from "../sport_display_text";

const Registeration=(props)=>{

    const {form_mode,id}= useParams();

    const [year,set_year]=useState([]);
    const [sport,set_sport]=useState([]);
    const [transaction_type,set_transaction_type]=useState([]);
    const [reason,set_reason]=useState([]);
    const [reason_display,set_reason_display]=useState([]);//สำหรับแสดงใน สาเหตุที่ไม่ผ่าน

    const [register_year,set_register_year]=useState("");
    const [sport_id,set_sport_id]=useState("");
    const [transaction_type_id,set_transaction_type_id]=useState("");
    const [not_pass_status,set_not_pass_status]=useState("");//สถานะที่ไม่ผ่าน
   

    const [name,set_name]=useState("");
    const [family_name,set_family_name]=useState("");
    const [registeration_code,set_registeration_code]=useState("");

    const form_ref=useRef();
    const history=useHistory();
    
    let [total_page, set_total_page] = useState(0);
    let [data_table, set_data_table] = useState([]);
    let [current_page, set_current_page] = useState(0);
    let [loading, set_loading] = useState(true);//state สำหรับสถานะกำลังโหลด
    let page_size=20;//กำหนด รายการที่แสดงต่อหน้า
  
    let [refresh, set_refresh] = useState(false);
    
    const didMount=useRef(false);

    const change_page=(row)=>{//ทำงานเมื่อกด แก้ไขข้อมูลผู้สมัคร จะเปลี่ยนไปหน้า 
        //เลื่อน scroll ขึ้นไปบนสุด 
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        //เปลี่ยนไปหน้า แก้ไขข้อมูลผู้สมัคร พร้อมทั้งส่ง id ของผู้สมัครไปด้วย
        history.push("/admin/registeration/form/"+row.registeration_id);

    }

    let clear_search=()=>{//clear การค้นหา 
        
        set_sport_id("");//clear การค้นหาตามกีฬาที่สมัคร
        set_transaction_type_id("");//clear การค้นหาตามสถานะการสมัคร ( มีสิทธิ์สอบทักษะกีฬา และสมรรถภาพทางกาย  มีสิทธิ์สอบสัมภาษณ์  มีสิทธิ์เข้าศึกษา )
        set_name("");//clear การค้นหาตามชื่อ
        set_family_name("");//clear การค้นหาตามนามสกุล
        set_register_year("");//clear การค้นหาตามปีการศึกษา
        set_registeration_code("");//clear การค้นหาตาม รหัสของผู้สมัคร
        // if(year.length!=0)
        // {
        //     set_register_year(year[0].register_year);
        // }

        set_refresh(true);//ให้ โหลดการค้นหาใหม่
    }

    let load_page= async (page_num)=>
    {
        
        if( ( page_num==null || page_num=="") && (page_num!=0))
        {
            page_num=current_page;
        }

  
        set_data_table([]);
        set_loading(true);
        set_total_page(0);
        set_current_page(parseInt(page_num));
        
        let json=null;
        let form_data=new FormData();
        form_data.append("token",localStorage["token"]);//ส้ง token เพื่อแสดงว่า login แล้ว
        form_data.append("mode","get");
        form_data.append("page_size",page_size);//โหลดข้อมูล จำนวนกี่แถวต่อหน้า
        form_data.append("page_num",page_num);//ต้องการ load ที่หน้าไหน
        //ส่งข้อมูลเงื่อนไขการค้นหา
        form_data.append("register_year",register_year);
        form_data.append("sport_id",sport_id);
        form_data.append("transaction_type_id",transaction_type_id);
        form_data.append("name",name);
        form_data.append("family_name",family_name);
        form_data.append("registeration_code",registeration_code);

        let response;
            try
            {
                response =await axios.post(server_url()+"api/registeration.php",form_data);
                json=response.data;
            }
            catch(ex)
            {
                console.log(ex);
                console.log(response);
                return;
            }
           
            if(json.length==0 && page_num!=0)
            {
                page_num--;
                load_page(page_num);
                return;
            }
            
            set_loading(false);
            set_total_page(json.total_page);//set state จำนวนหน้า
            set_data_table(json.data_table);//set state ข้อมูลผู้สมัคร
        
        

    }  
 
    useEffect(()=>{
        //ชุดคำสั่งที่ทำงานเมื่อโหลดหน้าเว็บ
        (async function(){
            //Load ข้อมูลปีการศึกษา
            let form_data=new FormData();
            form_data.append("mode","get_register_year");
            let response=await axios.post(server_url()+"api/registeration.php",form_data);
            set_year(response.data);

            //Load ข้อมูลชนิดกีฬา
            form_data=new FormData();
            form_data.append("mode","get_sport");
            form_data.append("all","1");//all แสดงว่า เอา  disabled  ด้วย

            response=await axios.post(server_url()+"api/register.php",form_data);
            set_sport(response.data);

            //Load ข้อมูลสถานะการสมัคร
            form_data=new FormData();
            form_data.append("mode","get_transaction_type");
            response=await axios.post(server_url()+"api/registeration.php",form_data);
            // //ปรัปสถานะการสมัคร เพิ่มไม่มีสิทธิเข้าไป
            // let temp=[...response.data];
            // let new_temp=[];
            // for(var i=0;i<temp.length;i++)
            // {
            //     new_temp.push(temp[i]);
            //     new_temp.push({
            //         transaction_type_id:"-"+temp[i].transaction_type_id,
            //         transaction_type_name:"ไม่"+temp[i].transaction_type_name,
            //     })

            // }

            // แยกสถานะมีสิทธิ กับไม่มีสิทธิ

            let status1=[];
            let status2=[];

            status1=response.data.filter(item=>{ return item.transaction_type_id < 5 });
            status2=response.data.filter(item=>{ return item.transaction_type_id >=5 });

            set_transaction_type(status1);
            set_not_pass_status(status2);
            //set_transaction_type(new_temp);

            form_data=new FormData();
            form_data.append("mode","get_reason");
            form_data.append("token",localStorage["token"]);//ส้ง token เพื่อแสดงว่า login แล้ว
            response=await axios.post(server_url()+"api/registeration.php",form_data);
            set_reason(response.data);

            await load_page(0);
 
        })();

    },[]);



    // const show_not_pass_option=(row)=>{ //แสดงตัวเลือกว่า สามารถมี สถานะอะไรได้บ้าง

    //     let not_pass_reason_arr=[];
    //     if(row.transaction_type_id==null)//ยังไม่มีสถานะ
    //     {
    //         not_pass_reason_arr=get_not_pass_reason("5");
    //     }
    //     else if(row.transaction_type_id==null)
    //     {
    //         not_pass_reason_arr=get_not_pass_reason("6");
    //     }
    //     else if(row.transaction_type_id==null)
    //     {
    //         not_pass_reason_arr=get_not_pass_reason("7");
    //     }

    //     let return_ele=(<>
        
            

    //     </>);

    //     return return_ele;
    // }

    const set_display_reason=(transaction_type_id)=>{ //แสดงเหตุผลของสถานะที่ไม่ผ่าน
        
        let returnArray=reason.filter(item=>{ 

            //if(transaction_type_id=="5")
            if(transaction_type_id==null)
            {
                return item.reason_type=="1" 
            }

            //if(transaction_type_id=="6")
            if(transaction_type_id=="2")
            {
                return item.reason_type=="2" 
            }

            //if(transaction_type_id=="7")
            if(transaction_type_id=="3")
            {
                return item.reason_type=="3" 
            }

            
        
        });
        
        return returnArray;
    };

    const get_transaction_type_from_reason_id=(reason_id)=>{ //หารหัสสถานะจากสาเหตุ

        if(reason_id=="1" || reason_id=="2" || reason_id=="3" )
        {
            return "5";//ไม่มีสิทธิ์สอบทักษะกีฬา และสมรรถภาพทางกาย
        }
        else if(reason_id=="4" || reason_id=="5" || reason_id=="6")
        {
            return "6";//ไม่มีสิทธิ์สอบสัมภาษณ์
        }
        else if(reason_id=="7" || reason_id=="8"  )
        {
            return "7";//ไม่มีสิทธิ์เข้าศึกษา
        }

    }

    useEffect(()=>{

        
        if(refresh)//ถ้า state refresh เป็น load หน้าใหม่ อีกครั้งโดย ไปที่หน้าแรก
        {
            set_refresh(false);
            load_page(0);
        }

    },[refresh]);


    //แสดง link เปลี่ยนหน้า
    let render_paging=()=>{

        if( total_page==0 ||  total_page==1 )
        {
            return null;
        }

        


        return (<nav   >
        <ul className="pagination mt-3 justify-content-center">
        
        {(current_page!=0)?<li className="page-item"><button onClick={() => load_page(0)} className="page-link disabled" type="button" ><i className="fa fa-step-backward"></i></button></li>:null}
        {(current_page!=0)?<li className="page-item"><button  onClick={() => load_page(current_page-1)} className="page-link" type="button" ><i className="fa fa-chevron-left"></i></button></li>:null}

          {((current_page-3) > 0)?<li className="page-item"><button className="page-link"  type="button" > ... </button></li>:null}
                
                {((current_page-2) >= 0)?<li className="page-item"><button  onClick={() => load_page(current_page-2)}  className="page-link"  type="button" >{current_page-1}</button></li>:null}
                {((current_page-1) >= 0)?<li className="page-item"><button  onClick={() => load_page(current_page-1)} className="page-link"  type="button" >{current_page}</button></li>:null}
                {((current_page) >= 0)?<li className="page-item"><button className="page-link text-danger"  type="button" >{current_page+1}</button></li>:null}
                {((current_page+1) < total_page)?<li className="page-item"><button onClick={() => load_page(current_page+1)} className="page-link"  type="button" >{current_page+2}</button></li>:null}
                {((current_page+2) < total_page)?<li className="page-item"><button onClick={() => load_page(current_page+2)} className="page-link"  type="button" >{current_page+3}</button></li>:null}
            
            {((current_page+3) < total_page)?<li className="page-item"><button className="page-link"  type="button" > ... </button></li>:null}
        
        {(current_page+1 < total_page)?<li className="page-item"><button  onClick={() => load_page(current_page+1)}  className="page-link" type="button" ><i className="fa fa-chevron-right"></i></button></li>:null}
        {(current_page+1 < total_page)?<li className="page-item"><button style={{outline:"none"}}  onClick={() => load_page(total_page-1)}className="page-link" type="button" > <i className="fa fa-step-forward"></i></button></li>:null}

        </ul>
      </nav>)

    }

    const get_age=(DOB)=>//function สำหรับค้นหาอายุตามวันเกิด
    {
        if(DOB=="")
        {
            return "";
        }


        //----นับ 1 มกราคม------
        let arr=DOB.split("-");
        arr[0]=arr[0];//ปีเกิด
        arr[1]="01";
        arr[2]="01";
        DOB=arr[0]+"-"+arr[1]+"-"+arr[2];

            var today = new Date();//วันที่ปัจจุบัน
            var birthDate = new Date(DOB);//วันที่ 1 มค ของปีเกิด
            var age = today.getFullYear() - birthDate.getFullYear();//หาอายุ
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age = age - 1;//หักอายุออก 1 ปี 
            }

            return age;
    }

    return (<Template>

        <h4>ข้อมูลผู้สมัคร</h4>
        <hr />
        {/*---- ถ้ามีค่า parameter form_mode และ id ของผู้สมัคร  ตาม path มา จะแสดง หน้าฟอร์มแก้ไขข้อมูลผู้สมัคร -----*/}
        {(form_mode!=null && id!=null)?
            <RegisterationForm ref={form_ref} refresh={load_page} id={id} />
        :null}

        <div className="mt-2 row">

            <div className="col-sm-3">
                <div className="mb-3">
                    <label className="form-label">เลขประจำตัวสอบ</label>
                    <input className="form-control form-control-sm" value={registeration_code} 
                        onKeyPress={event=>{if(event.key=="Enter"){load_page(0)} /*  ถ้ากด enter ใน textbox จะไปทำงานที่ function load_page   */ }}
                        onChange={event=>set_registeration_code(event.target.value)} />
                </div>
            </div>

            <div className="col-sm-3">
                <div className="mb-3">
                    <label className="form-label">ชื่อ</label>
                    <input className="form-control form-control-sm" value={name} 
                        onKeyPress={event=>{if(event.key=="Enter"){load_page(0)} /*  ถ้ากด enter ใน textbox จะไปทำงานที่ function load_page   */ }}
                        onChange={event=>set_name(event.target.value)} />
                </div>
            </div>
            <div className="col-sm-3">
                <div className="mb-3">
                    <label className="form-label">นามสกุล</label>
                    <input className="form-control form-control-sm" value={family_name} 
                        onKeyPress={event=>{if(event.key=="Enter"){load_page(0)} /*  ถ้ากด enter ใน textbox จะไปทำงานที่ function load_page   */ }}
                        onChange={event=>set_family_name(event.target.value)} />
                </div>
            </div>
            <div className="col-sm-3">
                <div className="mb-3">
                    <label className="form-label">ปีการศึกษาที่สมัคร</label>
                    <select className="form-select form-select-sm" value={register_year} 
                        onChange={event=>set_register_year(event.target.value)} >
                        
                        <option value=""></option>
                        {year.map(item=>
                            <option value={item.register_year}>{parseInt(item.register_year)+543}</option>    
                        )}

                    </select>
                </div>
            </div>

            <div className="col-sm-4">
                <div className="mb-3">
                    <label className="form-label">ชนิดกีฬา</label>
                    <select className="form-select form-select-sm" value={sport_id} 
                        onChange={event=>set_sport_id(event.target.value)} >
                        
                        <option value=""></option>
                        {sport.map(item=>
                                    <option value={item.sport_id}> {item.disable=="1"?" ปิดรับสมัคร - ":null} {getSportDisplay(item)}
                                    </option>    
                        )}

                    </select>
                </div>
            </div>

            <div className="col-sm-3">
                <div className="mb-3">
                    <label className="form-label">สถานะ</label>
                    <select className="form-select form-select-sm" value={transaction_type_id} 
                        onChange={event=>set_transaction_type_id(event.target.value)} >
                        
                        <option value=""></option>
                        <option value="-1">ยังไม่มีสถานะ</option>
                        {/*  นำสถานะการสมัครจาก state transaction_type มาแสดง */}
                        {transaction_type.map(item=>
                                    <option value={item.transaction_type_id}>
                                        {item.transaction_type_name}
                                    </option>    
                        )}

                    </select>
                </div>
            </div>
            <div className="col-sm-3">
                <div className="mb-3 " style={{marginTop:"30px"}}>
                    <label className="form-label"> </label>
                    <button className="btn btn-sm btn-success" type="button" onClick={event=>{
                        load_page(0); // เมื่อกด ค้นหา แล้วเรียก function load_page และส่ง 0 เพื่อไปที่หน้าแรก
                    }}>
                        <i className="fa fa-search"></i> ค้นหา
                    </button>
                    &nbsp;
                    <button className="btn btn-sm btn-danger" type="button" onClick={event=>{
                        clear_search(); //เมื่อกด แสดงทั้งหมด จะเรียก function clear_search เพื่อ clear การค้นหา
                    }}>
                        <i className="fa fa-times"></i> แสดงทั้งหมด
                    </button>
                </div>
            </div>

        </div>


        <ul className="list-group mt-2">

                {(loading)?
                    <li className="list-group-item list-group-item-warning" >
                        กำลังโหลด.. 
                    </li>
                :null}

                {(!loading && data_table.length==0  )?
                    <li className="list-group-item list-group-item-danger" >
                        ไม่พบข้อมูล
                    </li>
                :null}

                {data_table.map((row,index)=>
                
                    <li className="list-group-item">
                        
                        <div className="row">
                            <div className="col-sm-2">
                                {/* ------แสดงรูปผู้สมัคร---- */}
                                <img style={{maxWidth:"100%",height:"250px"}} src={server_url()+row.applicant_picture} />
                                <div className=" mt-2">
                                    <button className="btn btn-sm btn-link" type="button" onClick={event=>{
                                        window.show_notification("สำเนาบัตรประชาชน","<img style='width:100%' src='"+server_url()+row.citizen_picture+"' />");
                                    }}>
                                        <i className="fa fa-image"></i> สำเนาบัตรประชาชน
                                    </button>
                                </div>
                                <div className=" mt-2">
                                    <button className="btn btn-sm btn-link" type="button" onClick={event=>{
                                        window.show_notification("สำเนาสูติบัตร","<img style='width:100%' src='"+server_url()+row.birth_certificate_picture+"' />");
                                    }}>
                                        <i className="fa fa-image"></i> สำเนาสูติบัตร
                                    </button>
                                </div>

                            </div>
                            <div className="col-sm-6">

                                <div><b className="text-primary">เลขประจำตัวสอบ</b>: {row.registeration_code}</div>
                                <div><b className="text-primary">ชื่อ - นามสกุล</b>: {row.title} {row.name} {row.family_name}</div>
                                <div><b className="text-primary">สมัครเข้าเรียนชั้น</b>: {row.edu_level.edu_level_name} </div>
                                <div><b className="text-primary">วันเกิด</b>: {date_thai(row.birth_date)} <b className="text-primary">อายุ</b>: {get_age(row.birth_date)} ปี</div>
                                <div><b className="text-primary">เลขที่ประจำตัวประชาชน</b>: {(row.citizen_id)}  </div>
                                <div><b className="text-primary">ชนิดกีฬา</b>: {row.sport.sport_name}
                                    &nbsp;{row.sport.gender!=""?row.sport.gender:null} 
                                    &nbsp;{row.sport.age1!="0"?" อายุ "+row.sport.age1+" ถึง "+row.sport.age2+" ปี":null}
                                    &nbsp;<b className="text-primary">สมัครเรียนปี</b>: {parseInt(row.register_year)+543}
                                </div>
                                <div><b className="text-primary">เบอร์โทรศัพท์</b>: {row.telephone_number}</div>
                                <div><b className="text-primary">เบอร์โทรศัพท์ ( ผู้ปกครอง ) </b>: {row.telephone_number_parent	}</div>

                                <div><b className="text-primary">โรงเรียน </b>: {row.school}  <b className="text-primary">จังหวัด</b>: {row.province.province_name}</div>

                                <div><b className="text-primary">Email</b>: {row.email}</div>
                                <div><b className="text-primary">ที่อยู่</b>: {row.address}</div>

                               

                                
                                

                            </div>
                            <div className="col-sm-4">

                                <div className="text-secondary">สถานะ :
                                    {(row.transaction_type!=null && row.transaction_type!="")?
                                        <u>&nbsp;{row.transaction_type.transaction_type_name}</u>:
                                        <u className="text-danger">&nbsp;ยังไม่มีสถานะ</u>}
                                </div>

                                {/* กรณ๊เป็นมีสาเหตุที่ไม่ผ่าน  */}
                                
                                {row.reason_registeration!=null?
                                    <div className="text-secondary">สาเหตุ : 
                                    <u>&nbsp;{(row.reason_registeration.reason_detail)}</u></div>
                                :null}
                                    
                                 
                                {
                                    (
                                        ( row.show_change_staus==null || row.show_change_staus==false )
                                    )?
                                        <div>
                                            <button className='btn btn-sm btn-primary mt-2' type="button" onClick={event=>{
                                                    
                                                    let temp=[...data_table];
                                                    temp[index].show_change_staus=true;
                                                    temp[index].show_change_not_pass_staus=false;
                                                    set_data_table(temp);
                                            }}>
                                                <i className="fa fa-pencil-alt"></i> เปลี่ยนสถานะ
                                            </button>
                                        </div>
                                :
                                    <div className="mt-2">
                                          
                                          <select className="form-select form-select-sm" value={row.new_transaction_type_id} 
                                            onChange={event=>{
                                                if(event.target.value=="")
                                                {
                                                    return;
                                                }

                                                let temp=[...data_table];
                                                temp[index].new_transaction_type_id=event.target.value;
                                                // temp[index].new_reason_id="";//clear สาเหตุที่ไม่มีสิทธิ
                                                // if(parseInt(event.target.value) >= 5)
                                                // {
                                                //     set_reason_display(set_display_reason(event.target.value));//แสดงตัวเลือกสาเหตุ
                                                // }
                                                set_data_table(temp);

                                            }} >
                                             <option value="">สถานะ</option>    
                                            {transaction_type.map(item=>
                                                        <option value={item.transaction_type_id}>
                                                            {item.transaction_type_name}
                                                        </option>    
                                            )}

                                        </select>

                                            
                                        
                                        <div className="mt-3">
                                                    <button className="btn btn-success btn-sm" data-loading-text="กำลังบันทึก..." onClick={event=>{

                                                            if(row.new_transaction_type_id==null || row.new_transaction_type_id=="")
                                                            {
                                                                return;
                                                            }
                                                            let b=event.currentTarget;
                                                            loading_button(b);

                                                            let form_data=new FormData();
                                                            form_data.append("mode","change_status");
                                                            form_data.append("token",localStorage["token"]);
                                                            form_data.append("registeration_id",row.registeration_id);
                                                            form_data.append("transaction_type_id",row.new_transaction_type_id);
                                                            if(row.new_reason_id!=null)
                                                            {
                                                                form_data.append("reason_id",row.new_reason_id);

                                                            }

                                                            axios.post(server_url()+"api/registeration.php",form_data).then(response=>{



                                                                reset_button(b);

                                                                if(response.data.error!=null)
                                                                {
                                                                    window.show_notification("เกิดข้อผิดพลาด",response.data.error);
                                                                    return;
                                                                }
                                                                load_page(current_page);

                                                            });
                                                            }}>
                                                                <i className="fa fa-check"></i> ยืนยันเปลี่ยนสถานะ
                                                    </button>
                                                    &nbsp;
                                                    <button className="btn btn-secondary btn-sm" 
                                                        onClick={event=>{
                                                            let temp=[...data_table];
                                                            temp[index].show_change_staus=false;
                                                            temp[index].show_change_not_pass_staus=false;
                                                            temp[index].new_reason_id=null;
                                                            set_data_table(temp);
                                                        }}
                                                    >
                                                            <i className="fa fa-times-circle"></i> ยกเลิก
                                                    </button>
                                        </div>

                                    </div> 
                                }

                                {(row.show_change_not_pass_staus==null || row.show_change_not_pass_staus==false )?
                                    <>
                                        { (row.transaction_type_id==null)||( parseInt(row.transaction_type_id)  < 5  ) /* ถ้าไม่ผ่านแล้วไม่ต้องใส่เหตุผล  */ ?
                                            <div>
                                                <button className='btn btn-sm btn-primary mt-2' type="button" onClick={event=>{
                                                    
                                                    let temp=[...data_table];
                                                    temp[index].show_change_staus=false;
                                                    temp[index].show_change_not_pass_staus=true;
                                                    set_data_table(temp);
                                            }}>
                                                <i className="fa fa-pencil-alt"></i> เหตุผลที่ไม่ผ่าน 
                                            </button>
                                            </div>
                                        :null}
                                    </>
                                            
                                :   
                                    <div>
                                        <div className='mt-2'>
                                            <label>สาเหตุที่ไม่ผ่าน</label>
                                            <select className='form-select form-select-sm'  value={row.new_reason_id} 
                                                onChange={event=>{
                                                    let temp=[...data_table];
                                                    temp[index].new_reason_id=parseInt(event.target.value);
                                                    set_data_table(temp);
                                                }}
                                            >
                                                
                                                <option value=""></option>
                                                {set_display_reason(row.transaction_type_id).map(item=>
                                                    <option value={item.reason_id}>{item.reason_detail}</option>    
                                                )}        
                                            </select>
                                        </div>
                                        <div className='mt-3' >
                                                    <button className="btn btn-success btn-sm" data-loading-text="กำลังบันทึก..." onClick={event=>{

                                                                    if(row.new_reason_id==null || row.new_reason_id=="")
                                                                    {
                                                                        return;
                                                                    }
                                                                    let b=event.currentTarget;
                                                                    loading_button(b);

                                                                    let form_data=new FormData();
                                                                    form_data.append("mode","change_status");
                                                                    form_data.append("token",localStorage["token"]);
                                                                    form_data.append("registeration_id",row.registeration_id);

                                                                    //สถานะตามสาเหตุ

                                                                    form_data.append("transaction_type_id",get_transaction_type_from_reason_id(row.new_reason_id));
                                                                    form_data.append("reason_id",row.new_reason_id);
 

                                                                    axios.post(server_url()+"api/registeration.php",form_data).then(response=>{

                                                                        reset_button(b);

                                                                        if(response.data.error!=null)
                                                                        {
                                                                            window.show_notification("เกิดข้อผิดพลาด",response.data.error);
                                                                            return;
                                                                        }
                                                                        load_page(current_page);

                                                                    });
                                                                    }}>
                                                                        <i className="fa fa-check"></i> บันทึกสาเหตุ
                                                            </button>
                                                            &nbsp;               
                                                
                                                            <button className="btn btn-secondary btn-sm" 
                                                                onClick={event=>{
                                                                    let temp=[...data_table];
                                                                    temp[index].show_change_staus=false;
                                                                    temp[index].show_change_not_pass_staus=false;
                                                                    temp[index].new_transaction_type_id=null;
                                                                    set_data_table(temp);
                                                                }}
                                                            >
                                                                    <i className="fa fa-times-circle"></i> ยกเลิก
                                                            </button>
                                                </div>

                                    </div>
                                }
                                <div>
                                    <button className='btn btn-sm btn-info mt-2' type="button" onClick={event=>{
                                        change_page(row);//เรียก function change_page เพื่อไปหน้าแก้ไขข้อมูลผู้สมัคร
                                    }}>
                                        <i className="fa fa-pencil-alt"></i> แก้ไขข้อมูลผู้สมัคร
                                    </button>
                                </div>

                                <div className="mt-2">
                                    <button className="btn btn-sm btn-danger" type="button"
                                        onClick={event=>{
                                            let b=event.currentTarget;
                                            window.show_confirm("ต้องการลบ "+(row.name)+" "+row.family_name+" ?",()=>{

                                                loading_button(b);

                                                let form_data=new FormData();
                                                form_data.append("mode","del");
                                                form_data.append("registeration_id",row.registeration_id);
                                                form_data.append("token",localStorage["token"]);

                                                axios.post(server_url()+"api/registeration.php",form_data).then(response=>{

                                                    reset_button(b);

                                                    if(typeof(response.data)!="object")
                                                    {
                                                        console.log(response.data);
                                                        return;
                                                    }

                                                    if(response.data.error!=null)
                                                    {
                                                        window.show_notification("ไม่สามารถลบได้",response.data.error)
                                                        return;
                                                    }
                                                    //window.show_notification("ไม่สามารถลบได้","");

                                                    load_page(current_page);

                                                })


                                            });
                                        }}
                                    >
                                        <i className="fa fa-trash"></i> ลบข้อมูลผู้สมัคร
                                    </button>
                                </div>


                            </div>
                        </div>
                           
                        
                    </li>
                    
                )}
                </ul>

                

                {render_paging()}
        

    </Template>)

}

export default Registeration;