export var server_url=function()
{ 
    if(document.URL.indexOf("localhost")!=-1)//url ของ server ถ้ากำลังรันอยู่เครื่อง local  
    {
        return "http://localhost/athlete_recruitment/";
    }
    else
    {   //url ของ server จริง ถ้าผู้ใช้เข้าแบบ มี www
        if(document.URL.indexOf("www")==-1)
        {

            return "https://bkkculture.com/";            
            //return "https://division.bangkok.go.th/bangkokyouth/bangkoksportsschool/";
           // return "https://office2.bangkok.go.th/bangkokyouth/bangkoksportsschool/";
            //return "https://bangkoksportsschool.com/";
        }
        else
        {
            //url ของ server จริง ถ้าผู้ใช้เข้าแบบ ไม่มี www
            return "https://www.bkkculture.com/";            
            //return "https://www.division.bangkok.go.th/bangkokyouth/bangkoksportsschool/";
            //return "https://www.office2.bangkok.go.th/bangkokyouth/bangkoksportsschool/";
            //return "https://www.bangkoksportsschool.com/";
        }
    }
}

