
import axios from "axios";
import { forwardRef, useEffect, useImperativeHandle, useState ,useRef } from "react";
import { useHistory, useParams } from "react-router";
import { server_url } from "../config";
import {  loading_button,reset_button } from "../lib";

const DocumentFileForm=(props)=>{

    const history=useHistory();

    const [err,set_err]=useState("");
    const [loading,set_loading]=useState(null);
    const didMount = useRef(false);
    const {id}=useParams();

    const [document_file_name,set_document_file_name]=useState("");
    const [document_file_link,set_document_file_link]=useState("");

    const save= async (event)=>{

       

        if(
            document_file_name=="" ||
            document_file_link==""  
        )
        {
            set_err("กรุณากรอกข้อมูลให้ครบ");
            return;
        }

        let form_data=new FormData();
        form_data.append("token",localStorage["token"]);
        if(id==null)
        {
            form_data.append("mode","add");
        }
        else
        {
            form_data.append("mode","edit");
            form_data.append("document_file_id",id);
        }

        form_data.append("document_file_name",document_file_name);
        form_data.append("document_file_link",document_file_link);

        set_err("");
        let b=event.currentTarget;
        loading_button(b);

        let response=null;
        let json=null;
        
        try {

            response=await axios.post(server_url()+"api/document_file.php",form_data);
            reset_button(b);
            json= response.data;

            if(json.error!=null)
            {
                set_err(response.data.error);
                return;
            }
             
            if( typeof( json ) != "object")
            {
                console.log(json);
                set_err(response.data);
                return;
            }
            
        } catch (error) {

            console.log(response);
            set_err(response.data);
            return;
        }

        set_err(<div className="alert alert-success">บันทึกข้อมูลแล้ว</div>);
        
        setTimeout(()=>{
            history.replace("/admin/document_file");
            props.refresh();
        },1500);

    }

    useEffect(()=>{

        (async function(){

            set_loading(<div className="text-warning mb-2">กำลังโหลด...</div>);

                if(id!=null)
                {
                    let form_data=new FormData();
                    form_data.append("document_file_id",id);
                    form_data.append("mode","get");
                    form_data.append("token",localStorage["token"]);
                    form_data.append("page_size","1");
                    axios.post(server_url()+"api/document_file.php",form_data).then(res=>{
                        
                        set_loading(false);
                        if(res.data.data_table.length==0)
                        {
                            return;
                        }

                        let data=res.data.data_table[0];
                        set_document_file_name(data.document_file_name);
                        set_document_file_link(data.document_file_link);
                        
                    });
                }
            

        })()
        
    },[,props]);

    return (<div className="card mb-3">

            <div className="card-body">

                <div className="row">
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <label className="form-label">ชื่อเอกสาร <span className="text-danger">*</span></label>
                            <input className="form-control form-control-sm" value={document_file_name} onChange={event=>{set_document_file_name(event.target.value)}} />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <label className="form-label">Link <span className="text-danger">*</span></label>
                            <input className="form-control form-control-sm" value={document_file_link} onChange={event=>{set_document_file_link(event.target.value)}} />
                        </div>
                    </div>
                </div>

                    <div className="mt-3">
                        <div className="text-danger mb-3">{err}</div>
                        <button className="btn btn-sm btn-success" type="button"
                             data-loading-text="กำลังบันทึก"
                             onClick={event=>{ 
                                 save(event);
                             }}
                        ><i className="fa fa-check"></i> บันทึก</button>&nbsp;
                        <button className="btn btn-sm btn-secondary" type="button"
                            onClick={event=>{ history.push("/admin/document_file"); /*props.refresh();*/ }}
                        ><i className="fa fa-sign-out-alt"></i> กลับ</button>&nbsp;
                    </div>
                    

            </div>

    </div>);
}

export default DocumentFileForm;