import { useRef, useState } from "react";
import Template from "./template";
import Popup from './popup';
import { check_int,date_thai,loading_button,reset_button } from "./lib";
import axios from "axios";
import { server_url } from "./config";


const SearchRegisteration=()=>{

    const [citizen_id ,set_citizen_id ]=useState("");//state สำหรับกรอกเลขที่บัตรประจำตัวประชาชน
    const [telephone_number ,set_telephone_number ]=useState("");//state สำหรับเบอร์โทรศัพท์
    const [data_table,set_data_table]=useState([]);//state สำหรับเก็บผลการค้นหาข้อมูลผู้สมัคร
  // const [print_url,set_print_url]=useState("");

    const notification= useRef();//ตัวแปรอ้างอิง component ที่แจ้งเตือน
    //function สำหับค้นหาข้อมูลผู้สมัครตามเลขที่ประจำตัวประชาชน และเบอร์ฌทรศัพท์
    const search=async ()=>{

        if( citizen_id=="" || telephone_number=="")//ถ้าข้อมูลไม่ครบให้ขึ้น popup แจ้งเตือน
        {
            notification.current.open("ข้อมูลไม่ครบ","กรุณากรอกข้อมูลให้ครบ");
            return;
        }
        let b=document.getElementById("search_register_btn");//เก็บ object ปุ่มไว้ในตัวแปร b
        loading_button(b);//เปลี่ยนปุ่มให้เป็นสถานะโหลด

        set_data_table([]);//Clear ผมการค้นหาเดิม
        //เตรียมข้อมูลสำหรับส่งไปค้นหา
        let form_data=new FormData();
        form_data.append("mode","search_registeration");//ระบุ mode ให้ server ทราบว่าต้องการค้นหาผู้สมัคร
        form_data.append("citizen_id",citizen_id);//ส่งเขที่ประจำตัวประชาชนไปค้นหา
        form_data.append("telephone_number",telephone_number);//ส่งเบอร์โทรศัพท์ไปค้นหา

        let response=null;//สำหรับเก็บผลการค้นหา
        let json=null;//เก็บผลการค้นหาที่อยู่ในรูปแบบ json อีกทีนึง

        try
        {
            response=await axios.post(server_url()+"api/register.php",form_data);//ส่งเลขที่ประจำตัวประชาชน และเบอร์โทรศัพท์ไปค้นหาที่ไฟล์ register.php
            json=response.data;//เก็บข้อมูลผู้สมัครที่เจอไว้ในตัวแปร json
            //ถ้าส่งข้อมูลมาผิดพลาด ให้ console.log ออกมาดู
            if(typeof(json)!="object")
            {
                console.log(response.data);
                return;
            }

            if(json.length==0)//ถ้าไม่พบข้อมูลผู้สมัครให้ขึ้นข้อความแจ้งเตือน
            {
                notification.current.open("ไม่พบข้อมูล","ไม่พบข้อมูล การสมัคร");
            }
            reset_button(b);//เปลี่ยนปุ่มกลับเป็นสถานะปกติ
            set_data_table(json);//cแสดงผลการค้นหา


        }catch(ex)//ถ้าส่งข้อมูลมาผิดพลาด ให้ console.log ออกมาดู
        {
            console.log(ex);
            console.log(response.data);

            return;
        }

    }

    return (<Template>

        <h5>ดาวน์โหลดใบสมัคร</h5>
        <hr />
        {/* Component สำหรับแสดงข้อความแจ้งเตือน */}
        <Popup ref={notification} />
        <div className="row">

            <div className="col-sm-6">
                <div className="mb-3">
                    <label className="form-label">
                        เลขที่ประจำตัวประชาชน ( กรอกเฉพาะตัวเลข )
                    </label>
                    <input className="form-control form-control-sm" value={citizen_id} onKeyUp={event=>{ if(event.key=="Enter"){ search(); } /*----- ถ้ากด enter ใน textbox จะไปทำงาน ที่ function search----*/ }}
                     onChange={event=>{ set_citizen_id(check_int(event.target.value))  }} />
                     {/*  เมื่อ key ค่าในช่อง เลขที่ประจำตัวประชาชน จะเก็บข้อมูลไว้ใน state citizen_id และ ตัวตัวอักษรที่ไม่ใช่ ตัวเลขออกด้วย function check_int  */}
                </div>
            </div>
            <div className="col-sm-6">
                <div className="mb-3">
                    <label className="form-label">
                        เบอร์โทรศัพท์ของผู้สมัคร ( กรอกเฉพาะตัวเลข )
                    </label>
                    <input className="form-control form-control-sm" value={telephone_number} onKeyUp={event=>{ if(event.key=="Enter"){ search(); } /*----- ถ้ากด enter ใน textbox จะไปทำงาน ที่ function search----*/ }}
                     onChange={event=>{ set_telephone_number(check_int(event.target.value)) }} />
                     {/*  เมื่อ key ค่าในช่อง เบอร์โทรศัพท์ของผู้สมัคร จะเก็บข้อมูลไว้ใน state telephone_number และ ตัวตัวอักษรที่ไม่ใช่ ตัวเลขออกด้วย function check_int  */}

                </div>
            </div>

        </div>
        {/*-------ปุ่มค้นหาเมื่อกดแล้วจะไปทำงานที่ function search--------*/}
        <button className="btn btn-sm btn-primary" type="button" id="search_register_btn" data-loading-text="กำลังค้นหา..." onClick={event=>{
                    search();
            }}><i className="fa fa-search"></i> ค้นหา</button>
        {/*------------List แสดงผลการค้นหา---------*/}
        <ul className="mt-3 list-group">
            {data_table.map(row=>
                <li className="list-group-item">
                    <div className="row">
                        <div className="col-sm-10">
                           <div>  <b className="text-primary" >ชื่อนามสกุล</b> {row.title} {row.name} {row.family_name}</div>
                           <div> <b className="text-primary" >วันที่สมัคร</b> {date_thai(row.record_date)}  </div>
                           <div> <b className="text-primary" >กีฬาที่สมัคร</b> {row.sport_name} &nbsp;{row.gender!=""?row.gender:null} 
                           &nbsp;{row.age1!="0"?" อายุ "+row.age1+" ถึง "+row.age2+" ปี":null}
                           </div>
                        </div>
                        <div className="col-sm-2 text-right">
                            {/* ปุ่ม download pdf */}
                            <button className="btn btn-danger btn-sm"
                                onClick={event=>{
                                    let t=(new Date()).getTime();//ไม่ได้ใช้งานแล้ว (  print แบบ iframe เพราะ ios บาง เครื่อง ไม่ download pdf )
                                   
                                    //set_print_url(server_url()+"print.php?user_key="+row.user_key+"&t="+t);
                                    //document.getElementById("print_frame").src=server_url()+"pdf/index.php?user_key="+row.user_key+"&t="+t;
                                    //alert(document.getElementById("print_frame").src);
                                    window.open(server_url()+"pdf/index.php?user_key="+row.user_key,"_blank");//จะขึ้นหน้าให้ Download pdf ใน tab ใหม่
                                }}
                            >
                                <i className="fa fa-download"></i> Download PDF
                            </button>
                        </div>
                    </div>
                </li>    
            )}
        </ul>
        {/*  เลิกใช้งาน print แบบ iframe เพราะ ios บาง เครื่อง ไม่ download pdf */}                            
        <iframe id="print_frame"  style={{width:"0px",height:"0px"}}   />

    </Template>)

}

export default SearchRegisteration;