import Template from "./template";
import ScheduleForm from "./schedule_form";
import { useEffect, useRef, useState } from "react";
import { useHistory,useParams } from "react-router";
import { date_thai, loading_button, reset_button } from "../lib";
import axios from "axios";
import { server_url } from "../config";

const Schedule =()=>{

    const history=useHistory();
    const {form_mode}  = useParams();
    const form_ref=useRef();

    const [current_page, set_current_page] = useState(0);
    const [loading, set_loading] = useState(false);
    const [data_table, set_data_table] = useState([]);
    const [total_page, set_total_page] = useState(0);
    const page_size=10;
    
 
    const open_form=(row)=>{

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
       
        if(row==null)
        {
            history.push('/admin/schedule/form/');
        }
        else
        {
            history.push('/admin/schedule/form/'+row.schedule_id);
        }
        
        
    }

    const load_page=async (page_num)=>{
        
        if( ( page_num==null || page_num=="") && (page_num!=0))
        {
            page_num=current_page;
        }

  
        set_data_table([]);
        set_loading(true);
        set_total_page(0);
        set_current_page(parseInt(page_num));

        let json=null;
        let form_data=new FormData();
        form_data.append("token",localStorage["token"]);
        form_data.append("mode","get");
        form_data.append("page_size",page_size);

        let response;
        try
        {
            response =await axios.post(server_url()+"api/schedule.php",form_data);
            json=response.data;

            if(typeof (json)!="object")
            {
                console.log(json);
                return;
            }
        }
        catch(ex)
        {
            console.log(ex);
            console.log(response);
            return;
        }

        json=response.data;

        
        if(json.length==0 && page_num!=0)
        {
            page_num--;
            load_page(page_num);
            return;
        }
        
        set_loading(false);
        set_total_page(json.total_page);
        set_data_table(json.data_table);

    }

    useEffect(()=>{
        
        (async function(){

            await load_page();

        })()
        

    },[]);

    const render_paging=()=>{

        if( total_page==0 ||  total_page==1 )
        {
            return null;
        }

        /*----
        
        ไม่ให้ปุ่มมีกรอบ
        <style>
                .page-link:focus {
            outline: none !important;
            }
        </style>

        ---- */


        return (<nav   >
        <ul className="pagination mt-3 justify-content-center">
        
        {(current_page!=0)?<li className="page-item"><button onClick={() => load_page(0)} className="page-link disabled" type="button" ><i className="fa fa-step-backward"></i></button></li>:null}
        {(current_page!=0)?<li className="page-item"><button  onClick={() => load_page(current_page-1)} className="page-link" type="button" ><i className="fa fa-chevron-left"></i></button></li>:null}

          {((current_page-3) > 0)?<li className="page-item"><button className="page-link"  type="button" > ... </button></li>:null}
                
                {((current_page-2) >= 0)?<li className="page-item"><button  onClick={() => load_page(current_page-2)}  className="page-link"  type="button" >{current_page-1}</button></li>:null}
                {((current_page-1) >= 0)?<li className="page-item"><button  onClick={() => load_page(current_page-1)} className="page-link"  type="button" >{current_page}</button></li>:null}
                {((current_page) >= 0)?<li className="page-item"><button className="page-link text-danger"  type="button" >{current_page+1}</button></li>:null}
                {((current_page+1) < total_page)?<li className="page-item"><button onClick={() => load_page(current_page+1)} className="page-link"  type="button" >{current_page+2}</button></li>:null}
                {((current_page+2) < total_page)?<li className="page-item"><button onClick={() => load_page(current_page+2)} className="page-link"  type="button" >{current_page+3}</button></li>:null}
            
            {((current_page+3) < total_page)?<li className="page-item"><button className="page-link"  type="button" > ... </button></li>:null}
        
        {(current_page+1 < total_page)?<li className="page-item"><button  onClick={() => load_page(current_page+1)}  className="page-link" type="button" ><i className="fa fa-chevron-right"></i></button></li>:null}
        {(current_page+1 < total_page)?<li className="page-item"><button style={{outline:"none"}}  onClick={() => load_page(total_page-1)}className="page-link" type="button" > <i className="fa fa-step-forward"></i></button></li>:null}

        </ul>
      </nav>)

    }


    return (<div>

        <Template>

            <h4>กำหนดการ</h4>
            <hr />
            {form_mode=="form"?    

                    <ScheduleForm ref={form_ref} refresh={load_page}  />
                

            :null}
                
                
                    {form_mode!="form"?<button className="btn btn-sm btn-success" type="button" onClick={event=>open_form(null)} >
                        <i className="fa fa-plus"></i> เพิ่มกำหนดการ
                    </button>:null}
        
                
                    <ul className="list-group mt-3">

                        {(loading)?
                            <li className="list-group-item list-group-item-warning" >
                                กำลังโหลด.. 
                            </li>
                        :null}

                        {(!loading && data_table.length==0 )?
                            <li className="list-group-item list-group-item-danger" >
                                ไม่พบข้อมูล
                            </li>
                        :null}

                        

                        {data_table.map((row,index)=>

                            <li className="list-group-item">
                                
                                <div className="row">
                                    <div className="col-sm-10">

                                        <div><b className="text-primary">ปีการศึกษา </b> : {parseInt(row.register_year)+543} </div>
                                        <div><b className="text-primary">วันที่ </b> : {date_thai(row.schedule_date)} {row.topic} </div>


                                    </div>
                                    <div className="col-sm-2">

                                        <div className="d-grid gap-2">
                                            <button className="btn btn-primary btn-sm" type="button"
                                                onClick={event=>{
                                                    open_form(row);
                                                }}
                                            >
                                                <i className="fa fa-pencil-alt" ></i> แก้ไข
                                            </button>
                                            <button className="btn btn-danger btn-sm" type="button" data-loading-text="กำลังลบ..."
                                                onClick={event=>{
                                                    let b=event.currentTarget;
                                                    window.show_confirm("ลบ "+date_thai(row.schedule_date)+" "+row.topic+" ?",()=>{

                                                        loading_button(b);

                                                        let form_data=new FormData();
                                                        form_data.append("mode","del");
                                                        form_data.append("schedule_id",row.schedule_id);
                                                        form_data.append("token",localStorage["token"]);

                                                        axios.post(server_url()+"api/schedule.php",form_data).then(response=>{

                                                            reset_button(b);

                                                            if(typeof(response.data)!="object")
                                                            {
                                                                console.log(response.data);
                                                                return;
                                                            }

                                                            if(response.data.error!=null)
                                                            {
                                                                window.show_notification("ไม่สามารถลบได้",response.data.error)
                                                                return;
                                                            }
                                                            //window.show_notification("ไม่สามารถลบได้","");

                                                            load_page(current_page);

                                                        })


                                                    });
                                                }}
                                            >
                                                <i className="fa fa-trash"></i>  ลบ
                                            </button>
                                        </div>

                                    </div>
                                </div>
                                
                                
                            </li>
                            
                        )}
                    </ul>

                    {render_paging()}


        </Template>

    </div>);

}

export default Schedule;